import { useEffect, useState } from 'react'
import { Box, Container, Heading, Flex, Square, Image, Text, Icon, Center, Spinner } from '@chakra-ui/react'
import notfound from '../../assets/images/notfound.png'
import * as api from '../../services/api_service'
import { currencyFormat } from '../../utils/helper';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

function Transactions() {

  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const tTypeSearch = useLocation().search;
  const tTypeSearchName = new URLSearchParams(tTypeSearch).get('type') || 'deposits';
  const tTypeName = tTypeSearchName.toLowerCase();
  const [tab, setTab] = useState(tTypeName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  async function getTransactions() {
    setIsFetching(true);
    try {
      const resp = await Promise.all([
        api.deposits(),
        api.withdrawals()
      ]);
      setDeposits(resp[0].data.data);
      setWithdrawals(resp[1].data.data);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getTransactions()
  }, [])

  return (
    <>
      <Container maxW={'container.lg'} pb={5}>
        <Box h={'auto'} mt={{ base: 5, md: 10 }}>
          <Flex justifyContent={'space-between'} mb={5}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Transactions</Heading>
            <Flex>
              <Box mr={5}>
                <Box borderBottom={tab === 'deposits' ? 'solid 2px #fff' : 'none'} color={tab === 'deposits' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('deposits')}>Deposits</Box>
              </Box>
              <Box mr={2}>
                <Box borderBottom={tab === 'withdrawals' ? 'solid 2px #fff' : 'none'} color={tab === 'withdrawals' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('withdrawals')}>Withdrawals</Box>
              </Box>
            </Flex>
          </Flex>

          {
            tab === 'deposits' ?
              !isFetching ?
                deposits.length > 0 ?

                  //for deposits
                  <TableContainer>
                    <Table variant='simple'>
                      <TableCaption>All deposit transactions</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Status</Th>
                          <Th>Method</Th>
                          <Th isNumeric>Amount</Th>
                          <Th>To Address</Th>
                          <Th>Date</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {
                          deposits.map((e, k) => (
                            <Tr key={k}>
                              <Td>
                                {
                                  e.status === 1 ?
                                    <Text color={'yellow.600'}><Spinner size={'xs'} /> Pending </Text> :
                                    e.status === 0 ?
                                      <Flex color={'red.600'} gap={2}><Icon as={FaRegTimesCircle} /> <Text>Declined</Text> </Flex> :
                                      <Flex color={'green.600'} gap={2}><Icon as={FaRegCheckCircle} /> <Text>Success</Text> </Flex>
                                }
                              </Td>
                              <Td>{e.account}</Td>
                              <Td isNumeric>{currencyFormat(e.amount)}</Td>
                              <Td>{e.address}</Td>
                              <Td>{e.created_at}</Td>
                            </Tr>
                          ))
                        }
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Status</Th>
                          <Th>Method</Th>
                          <Th isNumeric>Amount</Th>
                          <Th>To Address</Th>
                          <Th>Date</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                  :
                  <Square h={'500px'} mt={{ base: '0px', md: '100px' }}>
                    <Box>
                      <Text textAlign={'center'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>No record found !</Text><br />
                      <Image src={notfound} w={{ base: '300px', md: '500px' }} />
                    </Box>
                  </Square>
                :
                <Center h={'140px'}>
                  <Spinner size={'lg'} />
                </Center>

              :

              //for closed

              !isFetching ?
                withdrawals.length > 0 ?
                  //for opened
                  <TableContainer>
                    <Table variant='simple'>
                      <TableCaption>All deposit transactions</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Status</Th>
                          <Th>Method</Th>
                          <Th isNumeric>Amount</Th>
                          <Th>To Address</Th>
                          <Th>Date</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {
                          withdrawals.map((e, k) => (
                            <Tr key={k}>
                              <Td>
                                {
                                  e.status === 1 ?
                                    <Box color={'yellow.600'}><Spinner size={'xs'} /> Pending </Box> :
                                    e.status === 0 ?
                                      <Flex color={'red.600'} gap={2}><Icon as={FaRegTimesCircle} /> <Text>Declined</Text> </Flex> :
                                      <Flex color={'green.600'} gap={2}><Icon as={FaRegCheckCircle} /> <Text>Success</Text> </Flex>
                                }
                              </Td>
                              <Td>{e.account}</Td>
                              <Td isNumeric>{currencyFormat(e.amount)}</Td>
                              <Td>{e.address}</Td>
                              <Td>{e.created_at}</Td>
                            </Tr>
                          ))
                        }
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Status</Th>
                          <Th>Method</Th>
                          <Th isNumeric>Amount</Th>
                          <Th>To Address</Th>
                          <Th>Date</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                  :
                  <Square h={'500px'} mt={{ base: '0px', md: '100px' }}>
                    <Box>
                      <Text textAlign={'center'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>No record found !</Text><br />
                      <Image src={notfound} w={{ base: '300px', md: '500px' }} />
                    </Box>
                  </Square>
                :
                <Center h={'140px'}>
                  <Spinner size={'lg'} />
                </Center>
          }
        </Box>
        <Box h={'100px'}></Box>
      </Container>
    </>
  )
}

export default Transactions