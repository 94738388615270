import { Box, Center, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

function Loader({opacity = 1}) {
  return (
    <Box bgColor={`rgba(0, 0, 0, ${opacity})`} color={'whiteAlpha.800'} w={'100%'} h={'100vh'}>
      <Center h={'inherit'}>
        <Box textAlign={'center'} mt={'-100px'}>
          <Spinner size={'lg'} thickness='2px' mb={5} />
          <Text>{process.env.REACT_APP_NAME}</Text>
        </Box>
      </Center>
    </Box>
  )
}

export default Loader