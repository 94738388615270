import React, { useEffect, useRef, useState } from 'react'
import useAuth from '../../../hooks/UseAuth';
import { Box, Center, FormErrorMessage, Heading, Image, Input, InputGroup, InputLeftElement, Spinner } from '@chakra-ui/react'
import { Card, CardHeader, CardBody, CardFooter, Text, FormControl, FormLabel, Button } from '@chakra-ui/react'
import { kBgColor, toastConfig } from '../../../utils/global'
import { useNavigate } from 'react-router-dom'
import { MdOutlineEmail } from 'react-icons/md'
import profileImg from '../../../assets/images/notfound2.png'
import { toast } from 'react-toastify';

const authCardStyle = {
    position: 'absolute',
    width: '420px',
    height: '420px'
}

function EmailOtp() {

    const isRendered = useRef(false);
    const navigate = useNavigate()
    const { authenticateEmailOTP, isAuthenticated, isAuthenticating, user, authError, setAuthError, authMiddleware, sendOtp, token, logout } = useAuth()

    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState([])

    useEffect(() => {

        authMiddleware()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, isAuthenticating])

    useEffect(() => {
        if (isRendered.current === true) {
            console.log(Object.keys(user).length)
            if (Object.keys(user).length > 0 && user.twofa === 0) {
                return navigate("/user", { replace: true })
            }

            if (Object.keys(user).length > 0 && user.twofa !== 0 && user.email_verified_at !== null) {
                return navigate("/user", { replace: true })
            }
        } else {
            isRendered.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, user])

    async function authUserOTP() {
        setOtpError([])

        const resp = await authenticateEmailOTP({ otp: otp })

        if (Object.keys(resp.error).length === 0) {
            return navigate('/user', { replace: true })
        } else {
            console.log('Otp Errors', resp.error)
            toast.error(resp.error.message, toastConfig)
            resp.error.errors.otp && setOtpError((err) => [...err, resp.error.errors.otp])
        }
    }

    async function resendOtp($type = 'email') {
        const resp = await sendOtp($type);
        if (Object.keys(resp.error).length === 0) {
            alert('OTP resent')
        }
    }

    return (
        <Box pt={{ base: '120px', md: '220px' }}>

            <Center p={5}>

                <Box pos={'relative'} width={{ base: '100%', md: '500px' }}>

                    <Image src={profileImg} style={authCardStyle} left={{ base: '-150px', md: '-350px' }} top={{ base: '-110px', md: '-150px' }} />

                    <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'2xl'} pos={'relative'} zIndex={1}>
                        <CardHeader>
                            <Heading size='md' textDecor={'underline'}>Two Factor Authenticator</Heading>
                        </CardHeader>
                        <CardBody>

                            <Box>
                                <FormControl mb={5} isInvalid={otpError.length > 0}>
                                    <FormLabel>OTP</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<MdOutlineEmail color='gray.300' />}
                                        />
                                        <Input type='text' placeholder='Enter OTP' onChange={(e) => {
                                            setOtp(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setOtpError([]);
                                        }} />
                                    </InputGroup>
                                    {otpError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                                </FormControl>

                                <Box mb={5} fontSize={'smaller'}>
                                    A one time token has been sent to
                                    <Text fontWeight={'bold'}>{user?.email}</Text>
                                </Box>

                                <FormControl mb={5} isInvalid={Object.keys(authError).length > 0} isDisabled={isAuthenticating}>
                                    {!isAuthenticating ?
                                        <Button colorScheme='blue' onClick={authUserOTP} variant={'outline'} w={'full'}>Authenticate</Button> :
                                        <Button colorScheme='blue' variant={'outline'} w={'full'}><Spinner /></Button>
                                    }
                                </FormControl>


                            </Box>

                        </CardBody>
                        <CardFooter>
                            <Box>
                                <Text mb={2} fontSize={'sm'}>Did not receive it ? <Button color='cyan.400' variant={'link'} size={'xs'} onClick={() => resendOtp()} disabled={isAuthenticating}>Resend</Button></Text>
                                <Text fontSize={'smaller'} textDecor={'underline'}><Button variant={'link'} size={'xs'} color='cyan.400' onClick={() => logout()}>Logout</Button></Text>
                            </Box>
                        </CardFooter>
                    </Card>
                </Box>
            </Center>
        </Box>
    )
}

export default EmailOtp