import { Box, Card, CardBody } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import { numberFormat } from '../utils/helper'

function OrderBook({ orderBook }) {
  return (
    <>
      <Card bgColor={'rgb(0, 0, 0)'} color={'whiteAlpha.800'} shadow={'dark-lg'} pb={2} mb={{base: '30px', md: '10px'}}>
        <CardBody>
          <Box h={'400px'} overflow={'hidden'}>
            <Box textAlign={'center'} borderBottom={'solid 1px rgba(240, 240, 240, 0.2)'} pb={2} mb={3}>Bid / Buy Orders</Box>
            {
              orderBook !== null ?
                <TableContainer>
                  <Table variant='unstyled' size={'sm'}>
                    <Thead>
                      <Tr>
                        <Th textAlign={'left'}>Price</Th>
                        <Th textAlign={'right'}>Total</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        orderBook.ask.length > 0 ?
                          orderBook.ask.map((e, i) => (
                            <Tr key={i} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                              <Td textAlign={'left'} color={'green.600'} fontSize={'13px'}>{numberFormat(e[0])}</Td>
                              <Td bgColor={'rgba(27, 85, 59, 0.282)'} fontSize={'13px'}>
                                <Box height={'inherit'} textAlign={'right'} >{numberFormat(e[1])}</Box>
                              </Td>
                            </Tr>
                          )) :
                          <Box>Order Book Record Not Found</Box>
                      }
                    </Tbody>
                  </Table>
                </TableContainer> :

                <Box mt={10}>Please wait ...</Box>
            }
          </Box>
        </CardBody>
      </Card>


      <Card bgColor={'rgb(0, 0, 0)'} color={'whiteAlpha.800'} shadow={'dark-lg'} pb={2}>
        <CardBody>
          <Box h={'400px'} overflow={'hidden'}>
            <Box textAlign={'center'} borderBottom={'solid 1px rgba(240, 240, 240, 0.2)'} pb={2} mb={3}>Ask / Sell Orders</Box>
            {
              orderBook !== null ?
                <TableContainer>
                  <Table variant='unstyled' size={'sm'}>
                    <Thead>
                      <Tr>
                        <Th textAlign={'left'}>Price</Th>
                        <Th textAlign={'right'}>Total</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        orderBook.ask.length > 0 ?
                          orderBook.ask.map((e, i) => (
                            <Tr key={i} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                              <Td textAlign={'left'} color={'red.700'} fontSize={'13px'}>{numberFormat(e[0])}</Td>
                              <Td bgColor={'rgba(85, 27, 27, 0.252)'} fontSize={'13px'}>
                                <Box height={'inherit'} textAlign={'right'} >{numberFormat(e[1])}</Box>
                              </Td>
                            </Tr>
                          )) :
                          <Box>Order Book Record Not Found</Box>
                      }
                    </Tbody>
                  </Table>
                </TableContainer> :

                <Box mt={10}>Please wait ...</Box>
            }
          </Box>
        </CardBody>
      </Card>
    </>
  )
}

export default OrderBook