import { Box, Container, Text, Heading } from '@chakra-ui/react'
import React from 'react'
import TvCryptoMarket from '../../components/tv/TvCryptoMarket'
import TvNews from '../../components/tv/TvNews'
import TvTicker from '../../components/tv/TvTicker'
import { ucfirst } from '../../utils/helper'
import UseAuth from '../../hooks/UseAuth'

function Analytics() {

  const {user} = UseAuth();
  return (
    <>
      <Box pos={'fixed'} w={'100%'} >
        <TvTicker />
      </Box>
      <Box h={'auto'} mt={{base: '90px', md: '60px'}} p={2}>
        <Container maxW={'container.lg'} pb={'100px'}>
          <Heading size={'lg'} borderBottom={'solid 3px #fff'} display={'inline-block'} pb={2} mb={2}>Welcome {ucfirst(user.firstname)} !</Heading>
          <Text mt={2} >Get insights to improve your trades and portfolio. There’s always room to grow. 
          Keep up with the news as it happens and know what’s impacting the markets at any time. 
          We provide fundamental, technical and sentiment tools for you to better understand the market and spot opportunities as they arise.</Text>

          <Heading size={'md'} mt={10}>Trending</Heading>
          <Box mt={5}>
            <TvCryptoMarket />
          </Box>

          <Heading size={'md'} mt={10}>News</Heading>
          <Box mt={5}>
            <TvNews />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Analytics