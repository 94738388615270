import { useRef, useState } from 'react'
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { CgMoreO } from 'react-icons/cg'

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import OrderBook from './OrderBook'
import Watchlist from './Watchlist'

function MobileMarketInfoDrawer({ symbols, orderBook, toggleWatchlist, watchlist, symbol24HrTicker }) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const [tab, setTab] = useState('orderbook');

  return (
    <>
      <Box w={'90%'} display={'flex'}>
        <Box fontSize={'26px'} mr={2}>
          <Box bgColor={'rgb(0, 0, 0)'} shadow={'dark-lg'} borderRadius={'100%'} mt={1} onClick={onOpen}>
            <CgMoreO />
          </Box>
        </Box>

        <Box bgColor={'blackAlpha.900'} shadow={'dark-lg'} borderRadius={'10px'} p={2} onClick={onOpen}>
          <Text fontSize={'11px'}>show</Text>
          <Text fontSize={'14px'}>Market Info</Text>
        </Box>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement='bottom'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor={'rgb(0, 0, 0)'} borderTopRightRadius={'20px'} borderTopLeftRadius={'20px'}>
          <DrawerCloseButton color={'whiteAlpha.700'} />
          <DrawerHeader color={'#fff'}>
            <Flex>
              <Box p={2} onClick={() => setTab('orderbook')} borderBottom={tab === 'orderbook' ? 'solid 2px #fff' : 'none'} mr={2}>
                <Text fontSize={'14px'}>Order Book</Text>
              </Box>
              <Box p={2} onClick={() => setTab('watchlist')} borderBottom={tab === 'watchlist' ? 'solid 2px #fff' : 'none'}>
                <Text fontSize={'14px'}>Watchlist</Text>
              </Box>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Box h={'auto'} maxH={'500px'} pb={5}>
              {
                tab === 'orderbook' ? 
                <OrderBook orderBook={orderBook} /> :
                <Watchlist watchlist={watchlist} toggleWatchlist={toggleWatchlist} symbols={symbols} symbol24HrTicker={symbol24HrTicker} />
              }
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileMarketInfoDrawer