import { Box, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import { useRef } from 'react'
import { FiPlusCircle } from 'react-icons/fi'

import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import TradeCard from './TradeCard'

function MobileTradeDrawer({ symbol, symbol24HrTicker, activeSymbolBuyPrice, maxBuyPrice, symbols, toggleWatchlist, watchlist }) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    return (
        <>
            <Box w={'100%'} display={'flex'}>

                <Box fontSize={'29px'} mr={2}>
                    <Box bgColor={'rgb(0, 0, 0)'} shadow={'dark-lg'} borderRadius={'100%'} onClick={onOpen}>
                        <FiPlusCircle />
                    </Box>
                </Box>

                <Box bgColor={'blackAlpha.900'} shadow={'dark-lg'} borderRadius={'10px'} p={2} onClick={onOpen}>
                    <Text fontSize={'11px'}>Click to</Text>
                    <Box fontSize={'13.5px'}>Trade {symbol ? symbol.name.toUpperCase() : <Spinner size={'sm'} />}</Box>
                </Box>
            </Box>

            <Drawer
                isOpen={isOpen}
                placement='bottom'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bgColor={'rgb(0, 0, 0)'} borderTopRightRadius={'20px'} borderTopLeftRadius={'20px'}>
                    <DrawerCloseButton color={'whiteAlpha.700'} />
                    <DrawerHeader color={'#fff'}><Text fontSize={'14px'}>Trade {symbol ? symbol.name.toUpperCase() : <Spinner size={'sm'} />}</Text></DrawerHeader>

                    <DrawerBody>
                        <Box h={'auto'} pb={5}>
                            <TradeCard symbol={symbol} symbol24HrTicker={symbol24HrTicker} activeSymbolBuyPrice={activeSymbolBuyPrice} maxBuyPrice={maxBuyPrice} symbols={symbols} toggleWatchlist={toggleWatchlist} watchlist={watchlist} />
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MobileTradeDrawer