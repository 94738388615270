import axios from "axios";
import { http } from "./http_service";

export function getPlans() {
    return http().get('/plans')
}

export function user() {
    return http().get('/user')
}

export function investments() {
    return http().get('/user/investments')
}

export function withdrawals() {
    return http().get('/user/withdrawals')
}

export function deposits() {
    return http().get('/user/deposits')
}

export function addresses() {
    return http().get('/user/addresses')
}

export function paymentAddresses() {
    return http().get('/user/payment/addresses')
}

export function downlines() {
    return http().get('/user/downlines')
}

export function symbols() {
    return http().get('/user/symbols')
}

export function downlineEarnings() {
    return http().get('/user/downline/earnings')
}

export function plans() {
    return http().get('/user/plans')
}

export function activities() {
    return http().get('/user/activities')
}

export function tickets() {
    return http().get('/user/tickets')
}

export function transfers() {
    return http().get('/user/transfers')
}

export function bots() {
    return http().get('/user/bots')
}

export function trades() {
    return http().get('/user/trades')
}

export function watchlist() {
    return http().get('/user/watchlist')
}

export function disconnectBot(id) {
    return http().get(`/user/bot/disconnect/${id}`)
}

export function tradesLimit(limit) {
    return http().get(`/user/trades/${limit}`)
}

export function subscriptions() {
    return http().get('/user/subscriptions')
}

export function trader(id) {
    return http().get(`/user/trader/${id}`)
}

export function ticket(id) {
    return http().get(`/user/ticket/${id}`)
}

export function validateTransferEmail(email) {
    return http().get(`/user/transfer/validate/${email}`)
}

export function deleteAddress(id) {
    return http().get(`/user/address/${id}`)
}

export function invest(data) {
    return http().post('/user/invest', data)
}

export function editProfile(data) {
    return http().post('/user/profile', data)
}

export function editProfileImage(data) {
    return http().post('/user/profile/image', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function addWallet(data) {
    return http().post('/user/address', data)
}

export function deposit(data) {
    return http().post('/user/deposit', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function requestPlan(data) {
    return http().post( '/user/plan', data);
}

export function traderSubscribe(data) {
    return http().post('/user/trader/subscribe', data)
}

export function traderUnsubscribe(data) {
    return http().post('/user/trader/unsubscribe', data)
}

export function trade(data) {
    return http().post('/user/trade', data)
}

export function walletToggle() {
    return http().post('/user/wallet-toggle')
}

export function toggle2FA() {
    return http().post('/user/2fa/toggle')
}

export function transfer(data) {
    return http().post('/user/transfer', data)
}

export function closePosition(data) {
    return http().post('/user/trade/close', data)
}

export function toggleWatchlist(data) {
    return http().post('/user/watchlist/toggle', data)
}

export function withdraw(type, data) {
    return http().post(`/user/withdraw/${type}`, data)
}

export function createTicket(data) {
    return http().post('/user/ticket/new', data)
}

export function sendTicketMessage(data) {
    return http().post('/user/ticket/comment', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function changePassword(data) {
    return http().post('/user/security/pass', data)
}

export function verifyID(data) {
    return http().post('/user/verify-id', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function login(data) {
    return http(false).post('/auth/login', data)
}

export function otp(data, type='email') {
    return http().post(`/auth/otp/${type}`, data)
}

export function sendOtp(type='email') {
    return http().get(`/auth/otp/${type}`)
}

export function signup(data) {
    return http(false).post('/auth/register', data)
}

export function logout() {
    return http().post('/auth/logout')
}

export function logoutDevice(data) {
    return http().post(`/auth/logout/${data}`)
}

export function test() {
    return axios.get('http://localhost:3000/api/v1/user/63cfb186f3084ef888f442b1')
}

export function getSymbolPrice(data, country) {
    return axios.get(`https://api.binance.${country === "United States" ? 'us' : 'com'}/api/v3/ticker/price?symbol=`+data);
}