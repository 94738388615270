import React, { createContext, useEffect, useMemo, useState } from 'react'
import * as api from '../services/api_service'
import { toast } from 'react-toastify'
import { toastConfig } from '../utils/global'

const AuthContext = createContext({})

export function AuthProvider({ children, verifyEmail = true, verifyPhone = false }) {

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [authError, setAuthError] = useState({})
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const [user, setUser] = useState({})
    const [orders, setOrders] = useState([]);

    const token = useMemo(() => {
        const token_ = localStorage.getItem('myapp-token');
        return !token_ ? null : token_ /*jwt.decode(token_)*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user])

    const liveTradingBalance = useMemo(() => {
        if (!isAuthenticated) return 0;
        const filteredOrders = orders.filter((e) => e.status === 1 && e.type === 'live');
        if (!filteredOrders.length === 0) return 0;

        let bal = 0;
        filteredOrders.forEach((e) => {
            bal += parseFloat(e.amount)
        })
        return bal;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user, orders])

    const demoTradingBalance = useMemo(() => {
        if (!isAuthenticated) return 0;
        const filteredOrders = orders.filter((e) => e.status === 1 && e.type === 'demo');
        if (!filteredOrders.length === 0) return 0;

        let bal = 0;
        filteredOrders.forEach((e) => {
            bal += parseFloat(e.amount)
        })
        return bal;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user, orders])

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                try {
                    const resp = await api.trades();
                    setOrders(resp.data.data);
                } catch (error) {
                    console.log("could not fetch trading balance");
                    toast.error("could not fetch trading balance", toastConfig)
                }
            }
        })()
    }, [isAuthenticated, user])

    useEffect(() => {
        async function fetchUser() {
            setIsAuthenticating(true)
            try {
                const resp = await api.user()
                setUser(resp.data.data)
                if (!verifyEmail && !verifyPhone) {
                    setIsAuthenticated(true)
                }
                setIsAuthenticating(false)

            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem('myapp-token');
                    setIsAuthenticated(false)
                }
                setIsAuthenticating(false)
            }
        }
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [])

    function authMiddleware() {
        if (token === null) {
            window.location.href = "/auth/login"
        }

        if (Object.keys(user).length === 0 && !isAuthenticating) {
            window.location.href = "/auth/login"
        }
    }

    function emailVerifiedMiddleware() {
        if (Object.keys(user).length > 0 && verifyEmail) {
            if (user.twofa === 1 && user.email_verified_at === null) {
                window.location.href = '/auth/email/otp'
            } else {
                setIsAuthenticated(true)
            }
        }
    }

    async function authenticate(data) {
        if (isAuthenticated) return false
        setIsAuthenticating(true)
        setAuthError({})
        try {
            const resp = await api.login(data)
            setToken(resp.data.token)
            console.log(resp.data.token)
            setUser(resp.data.user)
            // setIsAuthenticated(true)
            setIsAuthenticating(false)
            return ({
                data: resp.data.user,
                error: {},
            })
        } catch (error) {
            console.log('Auth Errors', error.response.data)
            setIsAuthenticating(false)
            localStorage.removeItem('myapp-token');
            setAuthError(error.response.data)
            setUser({})
            return ({
                data: {},
                error: error.response.data
            })
        }
    }

    async function signup(data) {
        if (isAuthenticated) return false
        setIsAuthenticating(true)
        setAuthError({})
        try {
            const resp = await api.signup(data)
            // console.log(resp.data.user)
            setToken(resp.data.token)
            setUser(resp.data.user)
            // setIsAuthenticated(true)
            setIsAuthenticating(false)
            return ({
                data: resp.data.user,
                error: {},
            })
        } catch (error) {
            // console.log('Auth Errors', error.response.data)
            setIsAuthenticating(false)
            localStorage.removeItem('myapp-token');
            setAuthError(error.response.data)
            setUser({})
            toast.error(error.response.data.message, toastConfig)
            return ({
                data: {},
                error: error.response.data
            })
        }
    }

    async function authenticateEmailOTP(data) {
        if (isAuthenticated) return false
        setIsAuthenticating(true)
        setAuthError({})
        try {
            const resp = await api.otp(data)
            // console.log(resp.data)
            setIsAuthenticated(true)
            setIsAuthenticating(false)
            setUser((user_) => ({ ...user_, email_verified_at: new Date().toLocaleString() }))
            return ({
                data: resp.data,
                error: {},
            })
        } catch (error) {
            // console.log('OTP Errors', error.response.data)
            setIsAuthenticating(false)
            setAuthError(error.response.data)
            return ({
                data: {},
                error: error.response.data
            })
        }
    }

    async function sendOtp($type = 'email') {
        if (isAuthenticated) return false
        setIsAuthenticating(true)
        setAuthError({})
        try {
            const resp = await api.sendOtp($type)
            console.log(resp.data)
            setIsAuthenticating(false)
            return ({
                data: resp.data,
                error: {},
            })
        } catch (error) {
            console.log('OTP Errors', error.response.data)
            setIsAuthenticating(false)
            setAuthError(error.response.data)
            return ({
                data: {},
                error: error.response.data
            })
        }
    }

    async function logout() {
        setIsLoggingOut(true)
        try {
            await api.logout()
            localStorage.removeItem('myapp-token');
            setIsAuthenticated(false)
            setUser({})
            setIsLoggingOut(false)
        } catch (error) {
            // console.log(error.response.data)
            setIsLoggingOut(false)
        }
        console.log('logged out')
    }

    async function logoutDevice(data) {
        setIsLoggingOut(true)
        try {
            const resp = await api.logoutDevice(data)
            console.log(resp.data)
            if (resp.data.logout === true) {
                localStorage.removeItem('myapp-token');
                setIsAuthenticated(false)
                setUser({})
            }
            setIsLoggingOut(false)
        } catch (error) {
            // console.log(error.response.data)
            setIsLoggingOut(false)
        }
        console.log('logged out')
    }

    function setToken(token) {
        // let token_ = jwt.sign(`${token}`, 'myappsecret');
        localStorage.setItem('myapp-token', token);
    }

    return (
        <AuthContext.Provider value={
            {
                isAuthenticated, setIsAuthenticated,
                isAuthenticating, setIsAuthenticating,
                authError, setAuthError,
                sendOtp,
                user, setUser,
                authenticate,
                authenticateEmailOTP,
                signup,
                emailVerifiedMiddleware,
                authMiddleware,
                logout,
                logoutDevice,
                isLoggingOut, setIsLoggingOut,
                token,
                orders, setOrders,
                liveTradingBalance,
                demoTradingBalance
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext