import { Box, Button, Card, CardBody, Center, Flex, Heading, Icon, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcApproval, FcCableRelease } from 'react-icons/fc'
import { disconnectBot, bots as getLinkedBots } from '../services/api_service'
import { kBgColor } from '../utils/global';
import { toast } from 'react-toastify';
import { ucfirst } from '../utils/helper';

function ConnectedBots() {

    const [bots, setBots] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isDisconnecting, setIsDisconnecting] = useState(false);


    async function getBots() {
        setIsFetching(true);
        try {
            const resp = await getLinkedBots();
            setBots(resp.data.data);
            setIsFetching(false);
        } catch (error) {
            console.log(error.response.message);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        getBots();
    }, [])

    async function handleDisconnectBot(id) {
        setIsDisconnecting(true)
        try {
            const resp = await disconnectBot(id);
            setBots((b) => b.filter(e => e.id !== id))
            toast.success(resp.data.message)
            setIsDisconnecting(false)
        } catch (error) {
            console.log(error.response)
            toast.error(error.response.message)
            setIsDisconnecting(false)
        }
    }

    return (
        <>
            <Heading fontSize={'18px'} mb={10} mt={10}>
                <Flex gap={3}>
                    <Icon as={FcCableRelease} w={8} h={8} />
                    <Text>Connected Apps</Text>
                </Flex>
            </Heading>

            <Box>
                {
                    !isFetching ?
                        bots.length > 0 ?
                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                                {
                                    bots.map((bot, k) => (
                                        <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'dark-lg'} key={k}>
                                            <CardBody>
                                                <Heading size={'sm'} mb={3}> {ucfirst(bot.bot.name)} <Icon as={FcApproval} /></Heading>
                                                <Text mb={4} color={'whiteAlpha.700'} fontSize={'sm'}>You connected to {bot.bot.name} on {bot.created_at}. Connected to {bot.account.email}</Text>
                                                <Text mb={4} color={'whiteAlpha.700'} fontSize={'sm'}>Note: This app will be able to execute trades on your behalf</Text>
                                                <Button variant={'outline'} size={'sm'} colorScheme={'yellow'} onClick={() => handleDisconnectBot(bot.id)} isDisabled={isDisconnecting}>Disconnect</Button>
                                            </CardBody>
                                        </Card>
                                    ))
                                }
                            </SimpleGrid>
                            :
                            <Center h={'200px'}>
                                <Text>No connected app found</Text>
                            </Center> :
                        <Center h={'200px'}>
                            <Spinner size={'md'} />
                        </Center>

                }
            </Box>
        </>
    )
}

export default ConnectedBots