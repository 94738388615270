import React from 'react'
import { Box, Flex, Text, Card, CardBody, Icon } from '@chakra-ui/react'
import { kBgColor } from '../utils/global';
import { numberFormat, ucfirst } from '../utils/helper';
import { MdOutlineSell } from 'react-icons/md';

function ClosePositionCard({ order }) {

    function resolvePNL() {
        if(order.profit == null) {
            return {
                pnl: (order.status === 2 ? order.amount : -(order.amount)).toFixed(2),
                roe: order.status === 2 ? 100 : -100
            }
        }
        
        return {
            pnl: (order.amount * (order.profit / 100)).toFixed(2),
            roe: order.profit
        }

    }

    return (
        <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'dark-lg'}>
            <CardBody>
                <Flex justifyContent={'space-between'}>
                    <Flex gap={3}>
                        <Icon as={MdOutlineSell} h={'25px'} />
                        <Text fontWeight={'bold'}>{order.symbol.name}</Text>
                        {/* <Text fontSize={'small'} color={'whiteAlpha.600'}>Cross 1X</Text> */}
                    </Flex>
                    <Text fontSize={10} color={'whiteAlpha.700'} textDecor={'underline'}>{ucfirst(order.type)} trade</Text>
                </Flex>

                <Flex justifyContent={'space-between'} pt={5} mb={3}>
                    <Box>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>PNL (USD)</Text>
                        <Text color={order.status === 2 ? 'green.600' : order.status === 1 ? 'yellow.600' : 'red.600'} fontWeight={'bold'}>{resolvePNL().pnl}</Text>
                    </Box>

                    <Box textAlign={'right'}>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>ROE</Text>
                        <Text color={order.status === 2 ? 'green.600' : order.status === 1 ? 'yellow.600' : 'red.600'} fontWeight={'bold'}>{resolvePNL().roe}%</Text>
                    </Box>
                </Flex>

                <Flex justifyContent={'space-between'} mb={3}>
                    <Box>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>Entry Price ({order.symbol.sell})</Text>
                        <Text fontSize={'14px'}>{numberFormat(order.entry)}</Text>
                    </Box>

                    <Box>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>Size ({order.symbol.buy})</Text>
                        <Text fontSize={'14px'}>{numberFormat(order.buy_amount)}</Text>
                    </Box>

                    <Box textAlign={'right'}>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>Margin ({order.symbol.sell})</Text>
                        <Text fontSize={'14px'}>{numberFormat(order.sell_amount)}</Text>
                    </Box>
                </Flex>

                <Flex justifyContent={'space-between'} mb={5}>
                    <Box>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>TP ({order.symbol.sell})</Text>
                        <Text fontSize={'14px'}>{order.tp === null ? '--' : numberFormat(order.tp)}</Text>
                    </Box>

                    <Box>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>SL ({order.symbol.sell})</Text>
                        <Text fontSize={'14px'}>{order.tp === null ? '--' : numberFormat(order.sl)}</Text>
                    </Box>

                    <Box textAlign={'right'}>
                        <Text fontSize={'small'} color={'whiteAlpha.600'}>Type</Text>
                        <Text fontSize={'14px'}>{order.symbol.order_type === 'limit order' ? 'Limit' : 'Market'}</Text>
                    </Box>
                </Flex>

            </CardBody>
        </Card>
    )
}

export default ClosePositionCard