
import { useMemo, useRef, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { kBgColor } from '../utils/global'
import { FaStar } from 'react-icons/fa'

import { Box, Flex, FormControl, Input, InputGroup, InputRightAddon, Icon, useDisclosure, Square, Spinner, Button } from '@chakra-ui/react'
import { MdOutlineManageSearch } from 'react-icons/md'
import { isStringEmpty } from '../utils/helper'

function SymbolSearch({ symbol24HrTicker, symbols, toggleWatchlist, watchlist, width = null }) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [searchInput, setSearchInput] = useState('');
    const searchInputRef = useRef();

    const filteredSymbols = useMemo(() => {
        if (symbols.length > 0 && !isStringEmpty(searchInput)) {
            const _filtered = symbols.filter((e) => `${e.name}`.toLowerCase().search(searchInput.toLowerCase()) + 1 > 0);
            console.log(_filtered)
            return _filtered;
        }
        return symbols
    }, [searchInput, symbols])

    // console.log(watchlist)

    function isInWatchlist(id) {
        return watchlist.filter((e) => e.symbol.id === parseInt(id)).length > 0
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={searchInputRef}>
                <ModalOverlay />
                <ModalContent bgColor={kBgColor} color={'whiteAlpha.800'}>
                    <ModalHeader>Search symbols</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <FormControl>
                                <InputGroup>
                                    <Input
                                        ref={searchInputRef}
                                        variant={'unstyled'}
                                        type='text'
                                        bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'}
                                        value={searchInput}
                                        placeholder={symbol24HrTicker !== null ? symbol24HrTicker.symbol : ''}
                                        color={'whiteAlpha.600'}
                                        w={{ base: '100%', md: '100%' }}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    <InputRightAddon children={<Icon as={MdOutlineManageSearch} />} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                                </InputGroup>
                            </FormControl>

                            <Box maxH={'200px'} overflowY={'auto'} mt={5}>
                                {
                                    symbols.length > 0 ?
                                        filteredSymbols.map((e, k) => (
                                            <Box key={k} padding={1} fontSize={'sm'} borderBottom={'1px solid rgba(200,200,200, 0.2)'} mb={1}>
                                                <Flex justifyContent={'space-between'}>
                                                    <Box flex={1} cursor={'pointer'} onClick={() => window.location.href = '/user/trader?symbol=' + `${e.name}`.toLowerCase()}>{e.name}</Box>
                                                    <Button onClick={() => toggleWatchlist(e.id)} variant={'link'} color={isInWatchlist(e.id) ? 'orange.400' : 'whiteAlpha.800'}><Icon as={FaStar} /></Button>
                                                </Flex>
                                            </Box>
                                        )) :
                                        <Square h={'100px'}>
                                            <Spinner />
                                        </Square>
                                }
                            </Box>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <InputGroup>
                <Input
                    variant={'unstyled'}
                    type='text'
                    bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'}
                    placeholder={symbol24HrTicker.symbol}
                    color={'whiteAlpha.600'}
                    w={{ base: width === null ? '100px' : width, md: width === null ? '200px' : width }}
                    // cursor={'pointer'}
                    onClick={onOpen}
                    readOnly />
                <InputRightAddon children={<Icon as={MdOutlineManageSearch} />} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
            </InputGroup>
        </>
    )
}

export default SymbolSearch