import { useEffect, useMemo, useState } from 'react'
import { Box, Container, Heading, Flex, Text, Button, Icon, Input } from '@chakra-ui/react'
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import * as api from '../../services/api_service'
import { toast } from 'react-toastify';
import { toastConfig } from '../../utils/global';
import { isStringEmpty } from '../../utils/helper';
import { FaStar } from 'react-icons/fa';

function Watchlist() {

  const [symbols, setSymbols] = useState([])
  const [watchlist, setWatchlist] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const filteredWatchlist = useMemo((e) => {
    if (!isStringEmpty(searchInput)) {
      const _filtered = watchlist.filter((e) => `${e.symbol.name}`.toLowerCase().search(searchInput.toLowerCase()) + 1 > 0);
      // console.log(_filtered)
      return _filtered;
    }
    return watchlist;
  }, [searchInput, watchlist])

  async function getDatas() {
    try {
      const resp = await Promise.all([
        api.symbols(),
        api.watchlist()
      ]);
      setSymbols(resp[0].data)
      setWatchlist(resp[1].data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDatas();
  }, [])

  async function toggleWatchlist(symbol_id) {
    try {
      const resp = await api.toggleWatchlist({ symbol_id: symbol_id })
      setWatchlist(resp.data.data)
      toast.success(resp.data.message, { ...toastConfig, autoClose: 2000 })
    } catch (error) {
      console.log(error.response.data)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  return (
    <>
      <Container maxW={'container.lg'} pb={5}>
        <Box h={'auto'} mt={{ base: 5, md: 10 }}>
          <Flex justifyContent={'space-between'} mb={5}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Watchlist</Heading>
          </Flex>

          <Input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} variant={'flushed'} bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Search ticker'} />

          <Box mt={5}>
            {
              watchlist.length > 0 ?

                //has records section
                <Box fontSize={'13px'}>
                  {/* <Text>Saved pairs will appear here.</Text> */}

                  <Box mt={5}>
                    <Text borderBottom={'solid 1px #fff'} display={'inline-block'} mb={3}>Saved Pairs</Text>

                    <TableContainer>
                      <Table variant='unstyled' size={'sm'}>
                        <Tbody>
                          {
                            filteredWatchlist.map((e, k) => (
                              <Tr key={k} _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                <Td cursor={'pointer'} onClick={() => window.location.href = '/user/trader?symbol=' + `${e.symbol.name}`.toLowerCase()} textAlign={'left'} fontSize={'12px'}>{e.symbol.name}</Td>
                                <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => toggleWatchlist(e.symbol.id)} variant={'link'} color={'orange.400'}><Icon as={FaStar} /></Button></Td>
                              </Tr>
                            ))
                          }
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box> :

                //no record section
                <Box fontSize={'13px'}>
                  <Text>You havent saved any ticker to watclist. navigate to charts to save symbols to watclist</Text>

                  <Box mt={5}>
                    <Text borderBottom={'solid 1px #fff'} display={'inline-block'} mb={3}>Popular</Text>

                    <TableContainer>
                      <Table variant='unstyled' size={'sm'}>
                        <Tbody>
                          <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                            <Td textAlign={'left'} fontSize={'12px'}>{symbols[0]?.name}</Td>
                            <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[0] && toggleWatchlist(symbols[0].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                          </Tr>
                          <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                            <Td textAlign={'left'} fontSize={'12px'}>{symbols[1]?.name}</Td>
                            <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[1] && toggleWatchlist(symbols[1].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                          </Tr>
                          <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                            <Td textAlign={'left'} fontSize={'12px'}>{symbols[2]?.name}</Td>
                            <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[2] && toggleWatchlist(symbols[2].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                          </Tr>
                          <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                            <Td textAlign={'left'} fontSize={'12px'}>{symbols[3]?.name}</Td>
                            <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[3] && toggleWatchlist(symbols[3].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                          </Tr>
                          <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                            <Td textAlign={'left'} fontSize={'12px'}>{symbols[4]?.name}</Td>
                            <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[4] && toggleWatchlist(symbols[4].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
            }
          </Box>
        </Box>
        <Box h={'100px'}></Box>
      </Container>
    </>
  )
}

export default Watchlist