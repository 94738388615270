import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/UseAuth';
import { Box, Center, FormErrorMessage, Heading, Image, Input, InputGroup, InputLeftElement, Link, Spinner } from '@chakra-ui/react'
import { Card, CardHeader, CardBody, CardFooter, Text, FormControl, FormLabel, Checkbox, Button } from '@chakra-ui/react'
import { kBgColor } from '../../utils/global'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { MdOutlineEmail, MdLockOutline } from 'react-icons/md'
import profileImg from '../../assets/images/notfound2.png'

const authCardStyle = {
  position: 'absolute',
  width: '420px',
  height: '420px'
}

function Login() {

  const navigate = useNavigate();
  const { authenticate, isAuthenticated, isAuthenticating, authError, setAuthError, token } = useAuth();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState([])
  const [passwordError, setPasswordError] = useState([])

  useEffect(() => {

    if (token !== null) {
      navigate("/user", { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token])

  async function loginUser() {
    setPasswordError([])
    setEmailError([])

    const resp = await authenticate({
      email: email,
      password: password
    })

    if (Object.keys(resp.error).length === 0) {
      return navigate('/user', { replace: true })
    } else {
      console.log('Login Errors', resp.error)
      resp.error.errors.password && setPasswordError((err) => [...err, resp.error.errors.password])
      resp.error.errors.email && setEmailError((err) => [...err, resp.error.errors.email])
    }
  }

  return (
    <Box pt={{ base: '120px', md: '220px' }}>

      <Center p={5}>

        <Box pos={'relative'} width={{ base: '100%', md: '500px' }}>

          <Image src={profileImg} style={authCardStyle} left={{ base: '-150px', md: '-350px' }} top={{ base: '-110px', md: '-150px' }} />

          <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'2xl'} pos={'relative'} zIndex={1}>
            <CardHeader>
              <Heading size='md' textDecor={'underline'}>Sign in</Heading>
            </CardHeader>
            <CardBody>

              <Box>
                <FormControl mb={5} isInvalid={emailError.length > 0}>
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdOutlineEmail color='gray.300' />}
                    />
                    <Input type='email' placeholder='example@company.com' onChange={(e) => {
                      setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                    }} />
                  </InputGroup>
                  {emailError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>


                <FormControl mb={10} isInvalid={passwordError.length > 0}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdLockOutline color='gray.300' />}
                    />
                    <Input type='password' placeholder='password' onChange={(e) => {
                      setPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPasswordError([])
                    }} />
                  </InputGroup>
                  {passwordError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5}>
                  <Checkbox><Text fontSize={'smaller'}>Remember me</Text></Checkbox>
                </FormControl>

                <FormControl mb={5} isInvalid={Object.keys(authError).length > 0} isDisabled={isAuthenticating}>
                  {!isAuthenticating ?
                    <Button colorScheme='blue' onClick={loginUser} variant={'outline'} w={'full'}>Login</Button> :
                    <Button colorScheme='blue' variant={'outline'} w={'full'}><Spinner /></Button>
                  }
                  {Object.keys(authError).length > 0 && <FormErrorMessage>{authError.message}</FormErrorMessage>}
                </FormControl>


              </Box>

            </CardBody>
            <CardFooter>
              <Box>
                <Text mb={2} fontSize={'sm'}>Not registered ? <Link as={RouterLink} to={'/auth/register'} color='cyan.400'>Create account</Link></Text>
                <Text fontSize={'smaller'}><Link href={process.env.REACT_APP_HOME_URL} as={RouterLink} color='cyan.400'>Goto Home</Link></Text>
              </Box>
            </CardFooter>
          </Card>
        </Box>
      </Center>
    </Box>
  )
}

export default Login