export function ucfirst(str) {
    //convert first letter to uppercase
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isStringEmpty(string) {
    return typeof string === 'string' && string.length === 0;
}

export function currencyFormat(data) {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(data)
}

export function numberFormat(data) {
    return (Number(data)).toLocaleString("en-US");
}

export function truncate(str, length = 25) {
    if (str.length < 1) return
    let truncatedString = str
    if (length > 10 && str.length > length) {
        truncatedString = str.substring(0, length - 3) + ' ...'
    }
    return truncatedString
}

export function formatDate(dateString) {
    let dateObject = new Date(dateString);
    let date = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + dateObject.getDate();
    let time = dateObject.getHours() + ":" + dateObject.getMinutes() + ":" + dateObject.getSeconds();
    let dateTime = date + ' ' + time;
    return dateTime;
}
