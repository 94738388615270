import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/UseAuth';
import { Box, Center, FormErrorMessage, Heading, Image, Input, InputGroup, InputLeftElement, Link, Select, Spinner } from '@chakra-ui/react'
import { Card, CardHeader, CardBody, CardFooter, Text, FormControl, FormLabel, Checkbox, Button } from '@chakra-ui/react'
import { kBgColor } from '../../utils/global'
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'
import { MdOutlineEmail, MdLockOutline, MdOutlinePhone } from 'react-icons/md'
import { BiUser } from 'react-icons/bi'
import profileImg from '../../assets/images/notfound2.png'
import { isStringEmpty } from '../../utils/helper';

const authCardStyle = {
  position: 'absolute',
  width: '420px',
  height: '420px'
}

function Register() {

  const navigate = useNavigate()
  const { signup, isAuthenticated, isAuthenticating, authError, setAuthError, token } = useAuth()
  const [searchParams] = useSearchParams();
  const queryParam_ = searchParams.get("ref") || null;
  const queryParam = queryParam_?.toLowerCase();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [gender, setGender] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')

  const [emailError, setEmailError] = useState([])
  const [passwordError, setPasswordError] = useState([])
  const [genderError, setGenderError] = useState([])
  // const [confirmPasswordError, setConfirmPasswordError] = useState([])
  const [firstnameError, setFirstnameError] = useState([])
  const [lastnameError, setLastnameError] = useState([])
  const [phoneError, setPhoneError] = useState([])
  const [countryError, setCountryError] = useState([])
  const [termsAccepted, setTermsAccepted] = useState(false)

  useEffect(() => {

    if (token !== null) {
      navigate("/user", { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token])

  async function signupUser() {
    setPasswordError([])
    setEmailError([])
    setFirstnameError([])
    setLastnameError([])
    setPhoneError([])
    setCountryError([])
    setGenderError([])
    const resp = await signup({
      email: email,
      password: password,
      gender: gender,
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      country: country,
      ref: queryParam,
    })

    if (Object.keys(resp.error).length === 0) {
      return navigate('/user', { replace: true })
    } else {
      console.log('Register Errors', resp.error)
      // resp.error.errors.password && console.log('password', resp.error.errors.password)
      resp.error.errors.password && setPasswordError((err) => [...err, resp.error.errors.password])
      resp.error.errors.email && setEmailError((err) => [...err, resp.error.errors.email])
      resp.error.errors.firstname && setFirstnameError((err) => [...err, resp.error.errors.firstname])
      resp.error.errors.lastname && setLastnameError((err) => [...err, resp.error.errors.lastname])
      resp.error.errors.phone && setPhoneError((err) => [...err, resp.error.errors.phone])
      resp.error.errors.country && setCountryError((err) => [...err, resp.error.errors.country])
      resp.error.errors.gender && setGenderError((err) => [...err, resp.error.errors.gender])
    }
  }

  function isNotValid() {
    return (isStringEmpty(email) || isStringEmpty(password) || isStringEmpty(firstname) || isStringEmpty(lastname) || isStringEmpty(phone)
      || isStringEmpty(country) || isStringEmpty(gender) || isAuthenticating || !termsAccepted)
  }

  return (
    <Box pt={{ base: '120px', md: '220px' }}>

      <Center p={5}>

        <Box pos={'relative'} width={{ base: '100%', md: '500px' }} pb={5}>

          <Image src={profileImg} style={authCardStyle} left={{ base: '-150px', md: '-350px' }} top={{ base: '-110px', md: '-150px' }} />

          <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'2xl'} pos={'relative'} zIndex={1}>
            <CardHeader>
              <Heading size='md' textDecor={'underline'}>Sign up</Heading>
            </CardHeader>
            <CardBody>

              <Box>
                <FormControl mb={5} isInvalid={firstnameError.length > 0}>
                  <FormLabel>First name</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<BiUser color='gray.300' />}
                    />
                    <Input type='text' value={firstname} placeholder='First name' onChange={(e) => {
                      setFirstname(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setFirstnameError([])
                    }} />
                  </InputGroup>
                  {firstnameError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5} isInvalid={lastnameError.length > 0}>
                  <FormLabel>Last name</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<BiUser color='gray.300' />}
                    />
                    <Input type='text' value={lastname} placeholder='Last name' onChange={(e) => {
                      setLastname(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setLastnameError([])
                    }} />
                  </InputGroup>
                  {lastnameError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5} isInvalid={emailError.length > 0}>
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdOutlineEmail color='gray.300' />}
                    />
                    <Input type='email' value={email} placeholder='example@company.com' onChange={(e) => {
                      setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                    }} />
                  </InputGroup>
                  {emailError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5} isInvalid={phoneError.length > 0}>
                  <FormLabel>Phone</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdOutlinePhone color='gray.300' />}
                    />
                    <Input type='tel' value={phone} placeholder='Phone' onChange={(e) => {
                      setPhone(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPhoneError([])
                    }} />
                  </InputGroup>
                  {phoneError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5} isInvalid={countryError.length > 0}>
                  <FormLabel>Country</FormLabel>
                  <InputGroup>
                    {/* <InputLeftElement
                      pointerEvents='none'
                      children={<FiGlobe color='gray.300' />}
                    /> */}
                    <Select value={country} placeholder='Select option' onChange={(e) => {
                      setCountry(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setCountryError([])
                    }}>
                      <option style={{ 'color': '#000' }} value="Afganistan">Afghanistan</option>
                      <option style={{ 'color': '#000' }} value="Albania">Albania</option>
                      <option style={{ 'color': '#000' }} value="Algeria">Algeria</option>
                      <option style={{ 'color': '#000' }} value="American Samoa">American Samoa</option>
                      <option style={{ 'color': '#000' }} value="Andorra">Andorra</option>
                      <option style={{ 'color': '#000' }} value="Angola">Angola</option>
                      <option style={{ 'color': '#000' }} value="Anguilla">Anguilla</option>
                      <option style={{ 'color': '#000' }} value="Antigua & Barbuda">Antigua & Barbuda</option>
                      <option style={{ 'color': '#000' }} value="Argentina">Argentina</option>
                      <option style={{ 'color': '#000' }} value="Armenia">Armenia</option>
                      <option style={{ 'color': '#000' }} value="Aruba">Aruba</option>
                      <option style={{ 'color': '#000' }} value="Australia">Australia</option>
                      <option style={{ 'color': '#000' }} value="Austria">Austria</option>
                      <option style={{ 'color': '#000' }} value="Azerbaijan">Azerbaijan</option>
                      <option style={{ 'color': '#000' }} value="Bahamas">Bahamas</option>
                      <option style={{ 'color': '#000' }} value="Bahrain">Bahrain</option>
                      <option style={{ 'color': '#000' }} value="Bangladesh">Bangladesh</option>
                      <option style={{ 'color': '#000' }} value="Barbados">Barbados</option>
                      <option style={{ 'color': '#000' }} value="Belarus">Belarus</option>
                      <option style={{ 'color': '#000' }} value="Belgium">Belgium</option>
                      <option style={{ 'color': '#000' }} value="Belize">Belize</option>
                      <option style={{ 'color': '#000' }} value="Benin">Benin</option>
                      <option style={{ 'color': '#000' }} value="Bermuda">Bermuda</option>
                      <option style={{ 'color': '#000' }} value="Bhutan">Bhutan</option>
                      <option style={{ 'color': '#000' }} value="Bolivia">Bolivia</option>
                      <option style={{ 'color': '#000' }} value="Bonaire">Bonaire</option>
                      <option style={{ 'color': '#000' }} value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                      <option style={{ 'color': '#000' }} value="Botswana">Botswana</option>
                      <option style={{ 'color': '#000' }} value="Brazil">Brazil</option>
                      <option style={{ 'color': '#000' }} value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                      <option style={{ 'color': '#000' }} value="Brunei">Brunei</option>
                      <option style={{ 'color': '#000' }} value="Bulgaria">Bulgaria</option>
                      <option style={{ 'color': '#000' }} value="Burkina Faso">Burkina Faso</option>
                      <option style={{ 'color': '#000' }} value="Burundi">Burundi</option>
                      <option style={{ 'color': '#000' }} value="Cambodia">Cambodia</option>
                      <option style={{ 'color': '#000' }} value="Cameroon">Cameroon</option>
                      <option style={{ 'color': '#000' }} value="Canada">Canada</option>
                      <option style={{ 'color': '#000' }} value="Canary Islands">Canary Islands</option>
                      <option style={{ 'color': '#000' }} value="Cape Verde">Cape Verde</option>
                      <option style={{ 'color': '#000' }} value="Cayman Islands">Cayman Islands</option>
                      <option style={{ 'color': '#000' }} value="Central African Republic">Central African Republic</option>
                      <option style={{ 'color': '#000' }} value="Chad">Chad</option>
                      <option style={{ 'color': '#000' }} value="Channel Islands">Channel Islands</option>
                      <option style={{ 'color': '#000' }} value="Chile">Chile</option>
                      <option style={{ 'color': '#000' }} value="China">China</option>
                      <option style={{ 'color': '#000' }} value="Christmas Island">Christmas Island</option>
                      <option style={{ 'color': '#000' }} value="Cocos Island">Cocos Island</option>
                      <option style={{ 'color': '#000' }} value="Colombia">Colombia</option>
                      <option style={{ 'color': '#000' }} value="Comoros">Comoros</option>
                      <option style={{ 'color': '#000' }} value="Congo">Congo</option>
                      <option style={{ 'color': '#000' }} value="Cook Islands">Cook Islands</option>
                      <option style={{ 'color': '#000' }} value="Costa Rica">Costa Rica</option>
                      <option style={{ 'color': '#000' }} value="Cote DIvoire">Cote DIvoire</option>
                      <option style={{ 'color': '#000' }} value="Croatia">Croatia</option>
                      <option style={{ 'color': '#000' }} value="Cuba">Cuba</option>
                      <option style={{ 'color': '#000' }} value="Curaco">Curacao</option>
                      <option style={{ 'color': '#000' }} value="Cyprus">Cyprus</option>
                      <option style={{ 'color': '#000' }} value="Czech Republic">Czech Republic</option>
                      <option style={{ 'color': '#000' }} value="Denmark">Denmark</option>
                      <option style={{ 'color': '#000' }} value="Djibouti">Djibouti</option>
                      <option style={{ 'color': '#000' }} value="Dominica">Dominica</option>
                      <option style={{ 'color': '#000' }} value="Dominican Republic">Dominican Republic</option>
                      <option style={{ 'color': '#000' }} value="East Timor">East Timor</option>
                      <option style={{ 'color': '#000' }} value="Ecuador">Ecuador</option>
                      <option style={{ 'color': '#000' }} value="Egypt">Egypt</option>
                      <option style={{ 'color': '#000' }} value="El Salvador">El Salvador</option>
                      <option style={{ 'color': '#000' }} value="Equatorial Guinea">Equatorial Guinea</option>
                      <option style={{ 'color': '#000' }} value="Eritrea">Eritrea</option>
                      <option style={{ 'color': '#000' }} value="Estonia">Estonia</option>
                      <option style={{ 'color': '#000' }} value="Ethiopia">Ethiopia</option>
                      <option style={{ 'color': '#000' }} value="Falkland Islands">Falkland Islands</option>
                      <option style={{ 'color': '#000' }} value="Faroe Islands">Faroe Islands</option>
                      <option style={{ 'color': '#000' }} value="Fiji">Fiji</option>
                      <option style={{ 'color': '#000' }} value="Finland">Finland</option>
                      <option style={{ 'color': '#000' }} value="France">France</option>
                      <option style={{ 'color': '#000' }} value="French Guiana">French Guiana</option>
                      <option style={{ 'color': '#000' }} value="French Polynesia">French Polynesia</option>
                      <option style={{ 'color': '#000' }} value="French Southern Ter">French Southern Ter</option>
                      <option style={{ 'color': '#000' }} value="Gabon">Gabon</option>
                      <option style={{ 'color': '#000' }} value="Gambia">Gambia</option>
                      <option style={{ 'color': '#000' }} value="Georgia">Georgia</option>
                      <option style={{ 'color': '#000' }} value="Germany">Germany</option>
                      <option style={{ 'color': '#000' }} value="Ghana">Ghana</option>
                      <option style={{ 'color': '#000' }} value="Gibraltar">Gibraltar</option>
                      <option style={{ 'color': '#000' }} value="Great Britain">Great Britain</option>
                      <option style={{ 'color': '#000' }} value="Greece">Greece</option>
                      <option style={{ 'color': '#000' }} value="Greenland">Greenland</option>
                      <option style={{ 'color': '#000' }} value="Grenada">Grenada</option>
                      <option style={{ 'color': '#000' }} value="Guadeloupe">Guadeloupe</option>
                      <option style={{ 'color': '#000' }} value="Guam">Guam</option>
                      <option style={{ 'color': '#000' }} value="Guatemala">Guatemala</option>
                      <option style={{ 'color': '#000' }} value="Guinea">Guinea</option>
                      <option style={{ 'color': '#000' }} value="Guyana">Guyana</option>
                      <option style={{ 'color': '#000' }} value="Haiti">Haiti</option>
                      <option style={{ 'color': '#000' }} value="Hawaii">Hawaii</option>
                      <option style={{ 'color': '#000' }} value="Honduras">Honduras</option>
                      <option style={{ 'color': '#000' }} value="Hong Kong">Hong Kong</option>
                      <option style={{ 'color': '#000' }} value="Hungary">Hungary</option>
                      <option style={{ 'color': '#000' }} value="Iceland">Iceland</option>
                      <option style={{ 'color': '#000' }} value="Indonesia">Indonesia</option>
                      <option style={{ 'color': '#000' }} value="India">India</option>
                      <option style={{ 'color': '#000' }} value="Iran">Iran</option>
                      <option style={{ 'color': '#000' }} value="Iraq">Iraq</option>
                      <option style={{ 'color': '#000' }} value="Ireland">Ireland</option>
                      <option style={{ 'color': '#000' }} value="Isle of Man">Isle of Man</option>
                      <option style={{ 'color': '#000' }} value="Israel">Israel</option>
                      <option style={{ 'color': '#000' }} value="Italy">Italy</option>
                      <option style={{ 'color': '#000' }} value="Jamaica">Jamaica</option>
                      <option style={{ 'color': '#000' }} value="Japan">Japan</option>
                      <option style={{ 'color': '#000' }} value="Jordan">Jordan</option>
                      <option style={{ 'color': '#000' }} value="Kazakhstan">Kazakhstan</option>
                      <option style={{ 'color': '#000' }} value="Kenya">Kenya</option>
                      <option style={{ 'color': '#000' }} value="Kiribati">Kiribati</option>
                      <option style={{ 'color': '#000' }} value="Korea North">Korea North</option>
                      <option style={{ 'color': '#000' }} value="Korea Sout">Korea South</option>
                      <option style={{ 'color': '#000' }} value="Kuwait">Kuwait</option>
                      <option style={{ 'color': '#000' }} value="Kyrgyzstan">Kyrgyzstan</option>
                      <option style={{ 'color': '#000' }} value="Laos">Laos</option>
                      <option style={{ 'color': '#000' }} value="Latvia">Latvia</option>
                      <option style={{ 'color': '#000' }} value="Lebanon">Lebanon</option>
                      <option style={{ 'color': '#000' }} value="Lesotho">Lesotho</option>
                      <option style={{ 'color': '#000' }} value="Liberia">Liberia</option>
                      <option style={{ 'color': '#000' }} value="Libya">Libya</option>
                      <option style={{ 'color': '#000' }} value="Liechtenstein">Liechtenstein</option>
                      <option style={{ 'color': '#000' }} value="Lithuania">Lithuania</option>
                      <option style={{ 'color': '#000' }} value="Luxembourg">Luxembourg</option>
                      <option style={{ 'color': '#000' }} value="Macau">Macau</option>
                      <option style={{ 'color': '#000' }} value="Macedonia">Macedonia</option>
                      <option style={{ 'color': '#000' }} value="Madagascar">Madagascar</option>
                      <option style={{ 'color': '#000' }} value="Malaysia">Malaysia</option>
                      <option style={{ 'color': '#000' }} value="Malawi">Malawi</option>
                      <option style={{ 'color': '#000' }} value="Maldives">Maldives</option>
                      <option style={{ 'color': '#000' }} value="Mali">Mali</option>
                      <option style={{ 'color': '#000' }} value="Malta">Malta</option>
                      <option style={{ 'color': '#000' }} value="Marshall Islands">Marshall Islands</option>
                      <option style={{ 'color': '#000' }} value="Martinique">Martinique</option>
                      <option style={{ 'color': '#000' }} value="Mauritania">Mauritania</option>
                      <option style={{ 'color': '#000' }} value="Mauritius">Mauritius</option>
                      <option style={{ 'color': '#000' }} value="Mayotte">Mayotte</option>
                      <option style={{ 'color': '#000' }} value="Mexico">Mexico</option>
                      <option style={{ 'color': '#000' }} value="Midway Islands">Midway Islands</option>
                      <option style={{ 'color': '#000' }} value="Moldova">Moldova</option>
                      <option style={{ 'color': '#000' }} value="Monaco">Monaco</option>
                      <option style={{ 'color': '#000' }} value="Mongolia">Mongolia</option>
                      <option style={{ 'color': '#000' }} value="Montserrat">Montserrat</option>
                      <option style={{ 'color': '#000' }} value="Morocco">Morocco</option>
                      <option style={{ 'color': '#000' }} value="Mozambique">Mozambique</option>
                      <option style={{ 'color': '#000' }} value="Myanmar">Myanmar</option>
                      <option style={{ 'color': '#000' }} value="Nambia">Nambia</option>
                      <option style={{ 'color': '#000' }} value="Nauru">Nauru</option>
                      <option style={{ 'color': '#000' }} value="Nepal">Nepal</option>
                      <option style={{ 'color': '#000' }} value="Netherland Antilles">Netherland Antilles</option>
                      <option style={{ 'color': '#000' }} value="Netherlands">Netherlands (Holland, Europe)</option>
                      <option style={{ 'color': '#000' }} value="Nevis">Nevis</option>
                      <option style={{ 'color': '#000' }} value="New Caledonia">New Caledonia</option>
                      <option style={{ 'color': '#000' }} value="New Zealand">New Zealand</option>
                      <option style={{ 'color': '#000' }} value="Nicaragua">Nicaragua</option>
                      <option style={{ 'color': '#000' }} value="Niger">Niger</option>
                      <option style={{ 'color': '#000' }} value="Nigeria">Nigeria</option>
                      <option style={{ 'color': '#000' }} value="Niue">Niue</option>
                      <option style={{ 'color': '#000' }} value="Norfolk Island">Norfolk Island</option>
                      <option style={{ 'color': '#000' }} value="Norway">Norway</option>
                      <option style={{ 'color': '#000' }} value="Oman">Oman</option>
                      <option style={{ 'color': '#000' }} value="Pakistan">Pakistan</option>
                      <option style={{ 'color': '#000' }} value="Palau Island">Palau Island</option>
                      <option style={{ 'color': '#000' }} value="Palestine">Palestine</option>
                      <option style={{ 'color': '#000' }} value="Panama">Panama</option>
                      <option style={{ 'color': '#000' }} value="Papua New Guinea">Papua New Guinea</option>
                      <option style={{ 'color': '#000' }} value="Paraguay">Paraguay</option>
                      <option style={{ 'color': '#000' }} value="Peru">Peru</option>
                      <option style={{ 'color': '#000' }} value="Phillipines">Philippines</option>
                      <option style={{ 'color': '#000' }} value="Pitcairn Island">Pitcairn Island</option>
                      <option style={{ 'color': '#000' }} value="Poland">Poland</option>
                      <option style={{ 'color': '#000' }} value="Portugal">Portugal</option>
                      <option style={{ 'color': '#000' }} value="Puerto Rico">Puerto Rico</option>
                      <option style={{ 'color': '#000' }} value="Qatar">Qatar</option>
                      <option style={{ 'color': '#000' }} value="Republic of Montenegro">Republic of Montenegro</option>
                      <option style={{ 'color': '#000' }} value="Republic of Serbia">Republic of Serbia</option>
                      <option style={{ 'color': '#000' }} value="Reunion">Reunion</option>
                      <option style={{ 'color': '#000' }} value="Romania">Romania</option>
                      <option style={{ 'color': '#000' }} value="Russia">Russia</option>
                      <option style={{ 'color': '#000' }} value="Rwanda">Rwanda</option>
                      <option style={{ 'color': '#000' }} value="St Barthelemy">St Barthelemy</option>
                      <option style={{ 'color': '#000' }} value="St Eustatius">St Eustatius</option>
                      <option style={{ 'color': '#000' }} value="St Helena">St Helena</option>
                      <option style={{ 'color': '#000' }} value="St Kitts-Nevis">St Kitts-Nevis</option>
                      <option style={{ 'color': '#000' }} value="St Lucia">St Lucia</option>
                      <option style={{ 'color': '#000' }} value="St Maarten">St Maarten</option>
                      <option style={{ 'color': '#000' }} value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                      <option style={{ 'color': '#000' }} value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                      <option style={{ 'color': '#000' }} value="Saipan">Saipan</option>
                      <option style={{ 'color': '#000' }} value="Samoa">Samoa</option>
                      <option style={{ 'color': '#000' }} value="Samoa American">Samoa American</option>
                      <option style={{ 'color': '#000' }} value="San Marino">San Marino</option>
                      <option style={{ 'color': '#000' }} value="Sao Tome & Principe">Sao Tome & Principe</option>
                      <option style={{ 'color': '#000' }} value="Saudi Arabia">Saudi Arabia</option>
                      <option style={{ 'color': '#000' }} value="Senegal">Senegal</option>
                      <option style={{ 'color': '#000' }} value="Seychelles">Seychelles</option>
                      <option style={{ 'color': '#000' }} value="Sierra Leone">Sierra Leone</option>
                      <option style={{ 'color': '#000' }} value="Singapore">Singapore</option>
                      <option style={{ 'color': '#000' }} value="Slovakia">Slovakia</option>
                      <option style={{ 'color': '#000' }} value="Slovenia">Slovenia</option>
                      <option style={{ 'color': '#000' }} value="Solomon Islands">Solomon Islands</option>
                      <option style={{ 'color': '#000' }} value="Somalia">Somalia</option>
                      <option style={{ 'color': '#000' }} value="South Africa">South Africa</option>
                      <option style={{ 'color': '#000' }} value="Spain">Spain</option>
                      <option style={{ 'color': '#000' }} value="Sri Lanka">Sri Lanka</option>
                      <option style={{ 'color': '#000' }} value="Sudan">Sudan</option>
                      <option style={{ 'color': '#000' }} value="Suriname">Suriname</option>
                      <option style={{ 'color': '#000' }} value="Swaziland">Swaziland</option>
                      <option style={{ 'color': '#000' }} value="Sweden">Sweden</option>
                      <option style={{ 'color': '#000' }} value="Switzerland">Switzerland</option>
                      <option style={{ 'color': '#000' }} value="Syria">Syria</option>
                      <option style={{ 'color': '#000' }} value="Tahiti">Tahiti</option>
                      <option style={{ 'color': '#000' }} value="Taiwan">Taiwan</option>
                      <option style={{ 'color': '#000' }} value="Tajikistan">Tajikistan</option>
                      <option style={{ 'color': '#000' }} value="Tanzania">Tanzania</option>
                      <option style={{ 'color': '#000' }} value="Thailand">Thailand</option>
                      <option style={{ 'color': '#000' }} value="Togo">Togo</option>
                      <option style={{ 'color': '#000' }} value="Tokelau">Tokelau</option>
                      <option style={{ 'color': '#000' }} value="Tonga">Tonga</option>
                      <option style={{ 'color': '#000' }} value="Trinidad & Tobago">Trinidad & Tobago</option>
                      <option style={{ 'color': '#000' }} value="Tunisia">Tunisia</option>
                      <option style={{ 'color': '#000' }} value="Turkey">Turkey</option>
                      <option style={{ 'color': '#000' }} value="Turkmenistan">Turkmenistan</option>
                      <option style={{ 'color': '#000' }} value="Turks & Caicos Is">Turks & Caicos Is</option>
                      <option style={{ 'color': '#000' }} value="Tuvalu">Tuvalu</option>
                      <option style={{ 'color': '#000' }} value="Uganda">Uganda</option>
                      <option style={{ 'color': '#000' }} value="United Kingdom">United Kingdom</option>
                      <option style={{ 'color': '#000' }} value="Ukraine">Ukraine</option>
                      <option style={{ 'color': '#000' }} value="United Arab Erimates">United Arab Emirates</option>
                      <option style={{ 'color': '#000' }} value="United States of America">United States of America</option>
                      <option style={{ 'color': '#000' }} value="Uraguay">Uruguay</option>
                      <option style={{ 'color': '#000' }} value="Uzbekistan">Uzbekistan</option>
                      <option style={{ 'color': '#000' }} value="Vanuatu">Vanuatu</option>
                      <option style={{ 'color': '#000' }} value="Vatican City State">Vatican City State</option>
                      <option style={{ 'color': '#000' }} value="Venezuela">Venezuela</option>
                      <option style={{ 'color': '#000' }} value="Vietnam">Vietnam</option>
                      <option style={{ 'color': '#000' }} value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                      <option style={{ 'color': '#000' }} value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                      <option style={{ 'color': '#000' }} value="Wake Island">Wake Island</option>
                      <option style={{ 'color': '#000' }} value="Wallis & Futana Is">Wallis & Futana Is</option>
                      <option style={{ 'color': '#000' }} value="Yemen">Yemen</option>
                      <option style={{ 'color': '#000' }} value="Zaire">Zaire</option>
                      <option style={{ 'color': '#000' }} value="Zambia">Zambia</option>
                      <option style={{ 'color': '#000' }} value="Zimbabwe">Zimbabwe</option>
                    </Select>
                  </InputGroup>
                  {countryError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5} isInvalid={genderError.length > 0}>
                  <FormLabel>Gender</FormLabel>
                  <InputGroup>
                    {/* <InputLeftElement
                      pointerEvents='none'
                      children={<BiMaleFemale color='gray.300' />}
                    /> */}
                    <Select value={gender} placeholder='Select option' onChange={(e) => {
                      setGender(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setGenderError([])
                    }}>
                      <option style={{ 'color': '#000' }} value="male">Male</option>
                      <option style={{ 'color': '#000' }} value="female">Female</option>
                      <option style={{ 'color': '#000' }} value="none">Do not specify</option>
                    </Select>
                  </InputGroup>
                  {genderError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={10} isInvalid={passwordError.length > 0}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdLockOutline color='gray.300' />}
                    />
                    <Input value={password} type='password' placeholder='password' onChange={(e) => {
                      setPassword(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setPasswordError([])
                    }} />
                  </InputGroup>
                  {passwordError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5}>
                  <Checkbox isChecked={termsAccepted} onChange={() => setTermsAccepted((e) => e ? false : true)}><Text fontSize={'smaller'}>You agree to our terms and conditions</Text></Checkbox>
                </FormControl>

                <FormControl mb={5}>
                  <Button colorScheme='blue' variant={'outline'} w={'full'} isDisabled={isNotValid()} onClick={signupUser}>{isAuthenticating ? <Spinner /> : 'Submit'}</Button>
                </FormControl>


              </Box>

            </CardBody>
            <CardFooter>
              <Box>
                <Text mb={2} fontSize={'sm'}>Already registered ? <Link as={RouterLink} to={'/auth/login'} color='cyan.400'>Login</Link></Text>
                <Text fontSize={'smaller'}><Link href={process.env.REACT_APP_HOME_URL} as={RouterLink} color='cyan.400'>Goto Home</Link></Text>
              </Box>
            </CardFooter>
          </Card>
        </Box>
      </Center>
    </Box>
  )
}

export default Register