
import { useEffect, useMemo, useState, useRef } from 'react';
import { Image, Text, Box, SimpleGrid, Button, Flex, Icon, FormControl, InputGroup, Input, Select, Card, CardBody } from '@chakra-ui/react'
import UseAuth from '../hooks/UseAuth'
import { ucfirst } from '../utils/helper';
import { AiOutlinePoweroff } from 'react-icons/ai'
import * as api from '../services/api_service'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { kBgColor, toastConfig } from '../utils/global';
import { toast } from 'react-toastify';
import NewAddressButton from '../components/NewAddressButton'
import ManageAddressButton from '../components/ManageAddressButton'
import { FiEdit } from 'react-icons/fi';
import { BsCheckCircleFill } from "react-icons/bs";


function Profile() {

    const { user, setUser, isAuthenticated, logout } = UseAuth()
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [paymentAddresses, setPaymentAddresses] = useState([]);
    const [verifyIDImage, setVerifyIDImage] = useState(null);
    const [verifyIDType, setVerifyIDType] = useState('');

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [passwordError, setPasswordError] = useState(false)

    const [isFetching, setIsFetching] = useState(true)
    const [isEditing, setIsEditing] = useState(false)
    const uploadImageRef = useRef()
    const uploadVerifyIDRef = useRef()
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    useEffect(() => {
        async function getAddresses() {
            try {
                const resp = await Promise.all([
                    api.addresses(),
                    api.paymentAddresses(),
                ]);
                setIsFetching(false)
                setAddresses(resp[0].data.data)
                setPaymentAddresses(resp[1].data.data)
            } catch (error) {
                setIsFetching(false)
                console.log(error)
            }
        }

        getAddresses()
    }, [])

    const handleValidate = useMemo(() => {
        return firstname === '' || lastname === '' || phone === '' || gender === '' || country === '' || isFetching || address === '' || isEditing || !isAuthenticated ? true : false
    }, [firstname, lastname, phone, gender, country, address, isAuthenticated, isEditing, isFetching])

    useEffect(() => {
        if (isAuthenticated) {
            setFirstname(user.firstname)
            setLastname(user.lastname)
            user.phone === null ? setPhone('') : setPhone(user.phone)
            setGender(user.gender)
            setCountry(user.country)
            user.address === null ? setAddress('') : setAddress(user.address)
        }
    }, [isAuthenticated, user])

    async function handleEdit() {
        setIsEditing(true)
        try {
            const resp = await api.editProfile({ firstname: firstname, lastname: lastname, phone: phone, gender: gender, country: country, address: address })
            setUser(resp.data.data)
            toast.success('Profile Updated Successfully', toastConfig)
            setIsEditing(false)
        } catch (error) {
            // console.log(error)
            if (error.response.status === 422) {
                toast.error(error.response.data.message, toastConfig)
            } else {
                toast.error('Error Updating Profile', toastConfig)
            }
            setIsEditing(false)
        }
    }

    const fileChangeHandler = async (e) => {
        const file_ = e.target.files[0];
        if (file_) {
            if (!file_.type.match(imageMimeType)) {
                alert("Image mime type is not valid");
                return;
            }
            setIsEditing(true)
            try {
                const resp = await api.editProfileImage({ file: file_ })
                setUser((user_) => ({ ...user_, image: resp.data.image }))
                uploadImageRef.current.value = ''
                toast.success('Profile Image Updated Successfully', toastConfig)
                setIsEditing(false)
            } catch (error) {
                if (error.response.status === 422) {
                    toast.error(error.response.data.message, toastConfig)
                } else {
                    toast.error('Error Updating Profile', toastConfig)
                }
                setIsEditing(false)
            }
        }
    }

    const verifyIDFileChangeHandler = async (e) => {
        const file_ = e.target.files[0];
        if (file_) {
            if (!file_.type.match(imageMimeType)) {
                alert("Image mime type is not valid");
                return;
            }
            setVerifyIDImage(file_);
        } else {
            setVerifyIDImage(null)
        }
    }

    const handlePasswordValidate = useMemo(() => {
        return oldPassword === '' || newPassword === '' || confirmPassword === '' || isFetching || !isAuthenticated || passwordError ? true : false
    }, [oldPassword, newPassword, confirmPassword, isFetching, isAuthenticated, passwordError])

    function validatePassword() {
        if ((newPassword.length < 6 && confirmPassword.length > 0) || (confirmPassword.length < 6 && newPassword.length > 0)) {
            setPasswordError(true)
        } else {
            setPasswordError(false)
        }
    }

    async function handleChangePassword() {
        setIsFetching(true)

        try {
            await api.changePassword({ old: oldPassword, password: newPassword, password_confirmation: confirmPassword })
            setOldPassword('')
            setNewPassword('')
            setConfirmPassword('')
            toast.success('Password Changed Successfully', toastConfig)
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
            setIsFetching(false)
        }
    }

    async function verifyID() {
        console.log(verifyIDImage)
        setIsFetching(true)
        try {
            await api.verifyID({ type: verifyIDType, image: verifyIDImage})
            toast.success('ID verification sent successfully', toastConfig)
            window.location.reload();
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
            setIsFetching(false)
        }
    }

    return (
        <>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={10}>

                <Box pt={5}>
                    <Accordion defaultIndex={[0]}>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'}>
                                        General information
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box pt={2}>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Firstname</Text>
                                        <InputGroup>
                                            <Input variant={''} defaultValue={user.firstname} value={firstname} onChange={(e) => setFirstname(e.target.value)} type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Firstname'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Lastname</Text>
                                        <InputGroup>
                                            <Input variant={''} defaultValue={user.lastname} value={lastname} onChange={(e) => setLastname(e.target.value)} type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Lastname'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Email</Text>
                                        <InputGroup>
                                            <Input variant={''} defaultValue={user.email} value={user.email} isDisabled type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Email'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Phone</Text>
                                        <InputGroup>
                                            <Input variant={''} defaultValue={user.phone} value={phone} onChange={(e) => setPhone(e.target.value)} type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Phone'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Gender</Text>
                                        <InputGroup>
                                            <Input variant={''} defaultValue={user.gender} value={gender} onChange={(e) => setGender(e.target.value)} type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Gender'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Country</Text>
                                        <InputGroup>
                                            <Select variant={''} defaultValue={user.country} value={country} onChange={(e) => setCountry(e.target.value)} bgColor={'rgba(255,255,255,0.1)'} placeholder={'Select'} className='select'>
                                                <option style={{ color: '#000' }}>Afghanistan</option>
                                                <option style={{ color: '#000' }}>Albania</option>
                                                <option style={{ color: '#000' }}>Algeria</option>
                                                <option style={{ color: '#000' }}>American Samoa</option>
                                                <option style={{ color: '#000' }}>Andorra</option>
                                                <option style={{ color: '#000' }}>Angola</option>
                                                <option style={{ color: '#000' }}>Anguilla</option>
                                                <option style={{ color: '#000' }}>Antarctica</option>
                                                <option style={{ color: '#000' }}>Antigua And Barbuda</option>
                                                <option style={{ color: '#000' }}>Argentina</option>
                                                <option style={{ color: '#000' }}>Armenia</option>
                                                <option style={{ color: '#000' }}>Aruba</option>
                                                <option style={{ color: '#000' }}>Australia</option>
                                                <option style={{ color: '#000' }}>Austria</option>
                                                <option style={{ color: '#000' }}>Azerbaijan</option>
                                                <option style={{ color: '#000' }}>Bahamas The</option>
                                                <option style={{ color: '#000' }}>Bahrain</option>
                                                <option style={{ color: '#000' }}>Bangladesh</option>
                                                <option style={{ color: '#000' }}>Barbados</option>
                                                <option style={{ color: '#000' }}>Belarus</option>
                                                <option style={{ color: '#000' }}>Belgium</option>
                                                <option style={{ color: '#000' }}>Belize</option>
                                                <option style={{ color: '#000' }}>Benin</option>
                                                <option style={{ color: '#000' }}>Bermuda</option>
                                                <option style={{ color: '#000' }}>Bhutan</option>
                                                <option style={{ color: '#000' }}>Bolivia</option>
                                                <option style={{ color: '#000' }}>Bosnia and Herzegovina</option>
                                                <option style={{ color: '#000' }}>Botswana</option>
                                                <option style={{ color: '#000' }}>Bouvet Island</option>
                                                <option style={{ color: '#000' }}>Brazil</option>
                                                <option style={{ color: '#000' }}>British Indian Ocean Territory</option>
                                                <option style={{ color: '#000' }}>Brunei</option>
                                                <option style={{ color: '#000' }}>Bulgaria</option>
                                                <option style={{ color: '#000' }}>Burkina Faso</option>
                                                <option style={{ color: '#000' }}>Burundi</option>
                                                <option style={{ color: '#000' }}>Cambodia</option>
                                                <option style={{ color: '#000' }}>Cameroon</option>
                                                <option style={{ color: '#000' }}>Canada</option>
                                                <option style={{ color: '#000' }}>Cape Verde</option>
                                                <option style={{ color: '#000' }}>Cayman Islands</option>
                                                <option style={{ color: '#000' }}>Central African Republic</option>
                                                <option style={{ color: '#000' }}>Chad</option>
                                                <option style={{ color: '#000' }}>Chile</option>
                                                <option style={{ color: '#000' }}>China</option>
                                                <option style={{ color: '#000' }}>Christmas Island</option>
                                                <option style={{ color: '#000' }}>Cocos (Keeling) Islands</option>
                                                <option style={{ color: '#000' }}>Colombia</option>
                                                <option style={{ color: '#000' }}>Comoros</option>
                                                <option style={{ color: '#000' }}>Cook Islands</option>
                                                <option style={{ color: '#000' }}>Costa Rica</option>
                                                <option style={{ color: '#000' }}>Cote D&#039;Ivoire (Ivory Coast)</option>
                                                <option style={{ color: '#000' }}>Croatia (Hrvatska)</option>
                                                <option style={{ color: '#000' }}>Cuba</option>
                                                <option style={{ color: '#000' }}>Cyprus</option>
                                                <option style={{ color: '#000' }}>Czech Republic</option>
                                                <option style={{ color: '#000' }}>Democratic Republic Of The Congo</option>
                                                <option style={{ color: '#000' }}>Denmark</option>
                                                <option style={{ color: '#000' }}>Djibouti</option>
                                                <option style={{ color: '#000' }}>Dominica</option>
                                                <option style={{ color: '#000' }}>Dominican Republic</option>
                                                <option style={{ color: '#000' }}>East Timor</option>
                                                <option style={{ color: '#000' }}>Ecuador</option>
                                                <option style={{ color: '#000' }}>Egypt</option>
                                                <option style={{ color: '#000' }}>El Salvador</option>
                                                <option style={{ color: '#000' }}>Equatorial Guinea</option>
                                                <option style={{ color: '#000' }}>Eritrea</option>
                                                <option style={{ color: '#000' }}>Estonia</option>
                                                <option style={{ color: '#000' }}>Ethiopia</option>
                                                <option style={{ color: '#000' }}>External Territories of Australia</option>
                                                <option style={{ color: '#000' }}>Falkland Islands</option>
                                                <option style={{ color: '#000' }}>Faroe Islands</option>
                                                <option style={{ color: '#000' }}>Fiji Islands</option>
                                                <option style={{ color: '#000' }}>Finland</option>
                                                <option style={{ color: '#000' }}>France</option>
                                                <option style={{ color: '#000' }}>French Guiana</option>
                                                <option style={{ color: '#000' }}>French Polynesia</option>
                                                <option style={{ color: '#000' }}>French Southern Territories</option>
                                                <option style={{ color: '#000' }}>Gabon</option>
                                                <option style={{ color: '#000' }}>Gambia The</option>
                                                <option style={{ color: '#000' }}>Georgia</option>
                                                <option style={{ color: '#000' }}>Germany</option>
                                                <option style={{ color: '#000' }}>Ghana</option>
                                                <option style={{ color: '#000' }}>Gibraltar</option>
                                                <option style={{ color: '#000' }}>Greece</option>
                                                <option style={{ color: '#000' }}>Greenland</option>
                                                <option style={{ color: '#000' }}>Grenada</option>
                                                <option style={{ color: '#000' }}>Guadeloupe</option>
                                                <option style={{ color: '#000' }}>Guam</option>
                                                <option style={{ color: '#000' }}>Guatemala</option>
                                                <option style={{ color: '#000' }}>Guernsey and Alderney</option>
                                                <option style={{ color: '#000' }}>Guinea</option>
                                                <option style={{ color: '#000' }}>Guinea-Bissau</option>
                                                <option style={{ color: '#000' }}>Guyana</option>
                                                <option style={{ color: '#000' }}>Haiti</option>
                                                <option style={{ color: '#000' }}>Heard and McDonald Islands</option>
                                                <option style={{ color: '#000' }}>Honduras</option>
                                                <option style={{ color: '#000' }}>Hong Kong S.A.R.</option>
                                                <option style={{ color: '#000' }}>Hungary</option>
                                                <option style={{ color: '#000' }}>Iceland</option>
                                                <option style={{ color: '#000' }}>India</option>
                                                <option style={{ color: '#000' }}>Indonesia</option>
                                                <option style={{ color: '#000' }}>Iran</option>
                                                <option style={{ color: '#000' }}>Iraq</option>
                                                <option style={{ color: '#000' }}>Ireland</option>
                                                <option style={{ color: '#000' }}>Israel</option>
                                                <option style={{ color: '#000' }}>Italy</option>
                                                <option style={{ color: '#000' }}>Jamaica</option>
                                                <option style={{ color: '#000' }}>Japan</option>
                                                <option style={{ color: '#000' }}>Jersey</option>
                                                <option style={{ color: '#000' }}>Jordan</option>
                                                <option style={{ color: '#000' }}>Kazakhstan</option>
                                                <option style={{ color: '#000' }}>Kenya</option>
                                                <option style={{ color: '#000' }}>Kiribati</option>
                                                <option style={{ color: '#000' }}>Korea North</option>
                                                <option style={{ color: '#000' }}>Korea South</option>
                                                <option style={{ color: '#000' }}>Kuwait</option>
                                                <option style={{ color: '#000' }}>Kyrgyzstan</option>
                                                <option style={{ color: '#000' }}>Laos</option>
                                                <option style={{ color: '#000' }}>Latvia</option>
                                                <option style={{ color: '#000' }}>Lebanon</option>
                                                <option style={{ color: '#000' }}>Lesotho</option>
                                                <option style={{ color: '#000' }}>Liberia</option>
                                                <option style={{ color: '#000' }}>Libya</option>
                                                <option style={{ color: '#000' }}>Liechtenstein</option>
                                                <option style={{ color: '#000' }}>Lithuania</option>
                                                <option style={{ color: '#000' }}>Luxembourg</option>
                                                <option style={{ color: '#000' }}>Macau S.A.R.</option>
                                                <option style={{ color: '#000' }}>Macedonia</option>
                                                <option style={{ color: '#000' }}>Madagascar</option>
                                                <option style={{ color: '#000' }}>Malawi</option>
                                                <option style={{ color: '#000' }}>Malaysia</option>
                                                <option style={{ color: '#000' }}>Maldives</option>
                                                <option style={{ color: '#000' }}>Mali</option>
                                                <option style={{ color: '#000' }}>Malta</option>
                                                <option style={{ color: '#000' }}>Man (Isle of)</option>
                                                <option style={{ color: '#000' }}>Marshall Islands</option>
                                                <option style={{ color: '#000' }}>Martinique</option>
                                                <option style={{ color: '#000' }}>Mauritania</option>
                                                <option style={{ color: '#000' }}>Mauritius</option>
                                                <option style={{ color: '#000' }}>Mayotte</option>
                                                <option style={{ color: '#000' }}>Mexico</option>
                                                <option style={{ color: '#000' }}>Micronesia</option>
                                                <option style={{ color: '#000' }}>Moldova</option>
                                                <option style={{ color: '#000' }}>Monaco</option>
                                                <option style={{ color: '#000' }}>Mongolia</option>
                                                <option style={{ color: '#000' }}>Montserrat</option>
                                                <option style={{ color: '#000' }}>Morocco</option>
                                                <option style={{ color: '#000' }}>Mozambique</option>
                                                <option style={{ color: '#000' }}>Myanmar</option>
                                                <option style={{ color: '#000' }}>Namibia</option>
                                                <option style={{ color: '#000' }}>Nauru</option>
                                                <option style={{ color: '#000' }}>Nepal</option>
                                                <option style={{ color: '#000' }}>Netherlands Antilles</option>
                                                <option style={{ color: '#000' }}>Netherlands The</option>
                                                <option style={{ color: '#000' }}>New Caledonia</option>
                                                <option style={{ color: '#000' }}>New Zealand</option>
                                                <option style={{ color: '#000' }}>Nicaragua</option>
                                                <option style={{ color: '#000' }}>Niger</option>
                                                <option style={{ color: '#000' }}>Nigeria</option>
                                                <option style={{ color: '#000' }}>Niue</option>
                                                <option style={{ color: '#000' }}>Norfolk Island</option>
                                                <option style={{ color: '#000' }}>Northern Mariana Islands</option>
                                                <option style={{ color: '#000' }}>Norway</option>
                                                <option style={{ color: '#000' }}>Oman</option>
                                                <option style={{ color: '#000' }}>Pakistan</option>
                                                <option style={{ color: '#000' }}>Palau</option>
                                                <option style={{ color: '#000' }}>Palestinian Territory Occupied</option>
                                                <option style={{ color: '#000' }}>Panama</option>
                                                <option style={{ color: '#000' }}>Papua new Guinea</option>
                                                <option style={{ color: '#000' }}>Paraguay</option>
                                                <option style={{ color: '#000' }}>Peru</option>
                                                <option style={{ color: '#000' }}>Philippines</option>
                                                <option style={{ color: '#000' }}>Pitcairn Island</option>
                                                <option style={{ color: '#000' }}>Poland</option>
                                                <option style={{ color: '#000' }}>Portugal</option>
                                                <option style={{ color: '#000' }}>Puerto Rico</option>
                                                <option style={{ color: '#000' }}>Qatar</option>
                                                <option style={{ color: '#000' }}>Republic Of The Congo</option>
                                                <option style={{ color: '#000' }}>Reunion</option>
                                                <option style={{ color: '#000' }}>Romania</option>
                                                <option style={{ color: '#000' }}>Russia</option>
                                                <option style={{ color: '#000' }}>Rwanda</option>
                                                <option style={{ color: '#000' }}>Saint Helena</option>
                                                <option style={{ color: '#000' }}>Saint Kitts And Nevis</option>
                                                <option style={{ color: '#000' }}>Saint Lucia</option>
                                                <option style={{ color: '#000' }}>Saint Pierre and Miquelon</option>
                                                <option style={{ color: '#000' }}>Saint Vincent And The Grenadines</option>
                                                <option style={{ color: '#000' }}>Samoa</option>
                                                <option style={{ color: '#000' }}>San Marino</option>
                                                <option style={{ color: '#000' }}>Sao Tome and Principe</option>
                                                <option style={{ color: '#000' }}>Saudi Arabia</option>
                                                <option style={{ color: '#000' }}>Senegal</option>
                                                <option style={{ color: '#000' }}>Serbia</option>
                                                <option style={{ color: '#000' }}>Seychelles</option>
                                                <option style={{ color: '#000' }}>Sierra Leone</option>
                                                <option style={{ color: '#000' }}>Singapore</option>
                                                <option style={{ color: '#000' }}>Slovakia</option>
                                                <option style={{ color: '#000' }}>Slovenia</option>
                                                <option style={{ color: '#000' }}>Smaller Territories of the UK</option>
                                                <option style={{ color: '#000' }}>Solomon Islands</option>
                                                <option style={{ color: '#000' }}>Somalia</option>
                                                <option style={{ color: '#000' }}>South Africa</option>
                                                <option style={{ color: '#000' }}>South Georgia</option>
                                                <option style={{ color: '#000' }}>South Sudan</option>
                                                <option style={{ color: '#000' }}>Spain</option>
                                                <option style={{ color: '#000' }}>Sri Lanka</option>
                                                <option style={{ color: '#000' }}>Sudan</option>
                                                <option style={{ color: '#000' }}>Suriname</option>
                                                <option style={{ color: '#000' }}>Svalbard And Jan Mayen Islands</option>
                                                <option style={{ color: '#000' }}>Swaziland</option>
                                                <option style={{ color: '#000' }}>Sweden</option>
                                                <option style={{ color: '#000' }}>Switzerland</option>
                                                <option style={{ color: '#000' }}>Syria</option>
                                                <option style={{ color: '#000' }}>Taiwan</option>
                                                <option style={{ color: '#000' }}>Tajikistan</option>
                                                <option style={{ color: '#000' }}>Tanzania</option>
                                                <option style={{ color: '#000' }}>Thailand</option>
                                                <option style={{ color: '#000' }}>Togo</option>
                                                <option style={{ color: '#000' }}>Tokelau</option>
                                                <option style={{ color: '#000' }}>Tonga</option>
                                                <option style={{ color: '#000' }}>Trinidad And Tobago</option>
                                                <option style={{ color: '#000' }}>Tunisia</option>
                                                <option style={{ color: '#000' }}>Turkey</option>
                                                <option style={{ color: '#000' }}>Turkmenistan</option>
                                                <option style={{ color: '#000' }}>Turks And Caicos Islands</option>
                                                <option style={{ color: '#000' }}>Tuvalu</option>
                                                <option style={{ color: '#000' }}>Uganda</option>
                                                <option style={{ color: '#000' }}>Ukraine</option>
                                                <option style={{ color: '#000' }}>United Arab Emirates</option>
                                                <option style={{ color: '#000' }}>United Kingdom</option>
                                                <option style={{ color: '#000' }}>United States</option>
                                                <option style={{ color: '#000' }}>United States Minor Outlying Islands</option>
                                                <option style={{ color: '#000' }}>Uruguay</option>
                                                <option style={{ color: '#000' }}>Uzbekistan</option>
                                                <option style={{ color: '#000' }}>Vanuatu</option>
                                                <option style={{ color: '#000' }}>Vatican City State (Holy See)</option>
                                                <option style={{ color: '#000' }}>Venezuela</option>
                                                <option style={{ color: '#000' }}>Vietnam</option>
                                                <option style={{ color: '#000' }}>Virgin Islands (British)</option>
                                                <option style={{ color: '#000' }}>Virgin Islands (US)</option>
                                                <option style={{ color: '#000' }}>Wallis And Futuna Islands</option>
                                                <option style={{ color: '#000' }}>Western Sahara</option>
                                                <option style={{ color: '#000' }}>Yemen</option>
                                                <option style={{ color: '#000' }}>Yugoslavia</option>
                                                <option style={{ color: '#000' }}>Zambia</option>
                                                <option style={{ color: '#000' }}>Zimbabwe</option>
                                            </Select>
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={5}>
                                        <Text mb={3}>Address</Text>
                                        <InputGroup>
                                            <Input variant={''} defaultValue={user.address} value={address} onChange={(e) => setAddress(e.target.value)} type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Enter your home address'} />
                                        </InputGroup>
                                    </FormControl>
                                    <Button onClick={() => handleEdit()} colorScheme={'teal'} isDisabled={handleValidate}>Save all</Button>
                                    <Box h={'10px'} />
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'}>
                                        ID verification
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mt={5} mb={5}>
                                    <Box>
                                        {user.verify ?
                                            user.verify.status === 1 ? <Text mb={5} fontSize={'13px'} color={'yellow.500'}>You submitted an ID for verification. Kindly await confirmation ({user.verify.type}).</Text> :
                                                user.verify.status === 2 ? <Text fontWeight={'bold'} mb={5} fontSize={'13px'} color={'green.500'}>Verified ({user.verify.type}) <Icon as={BsCheckCircleFill} /></Text> :
                                                    <Text mb={5} fontSize={'13px'} color={'red.500'}>Your last submission was declined ({user.verify.type})</Text>
                                            : <Text mb={5} fontSize={'13px'} color={'red.500'}>You have not uploaded any form of verification</Text>}
                                    </Box>

                                    <Input ref={uploadVerifyIDRef} onChange={verifyIDFileChangeHandler} type="file" accept='.png, .jpg, .jpeg' display={'none'} />
                                    <FormControl mb={4}>
                                        <Text mb={1}>Select type</Text>
                                        <Text mb={3} fontSize={'12px'}> Select a goverment issued ID</Text>
                                        <InputGroup>
                                            <Select variant={''} value={verifyIDType} onChange={(e) => setVerifyIDType(e.target.value)} bgColor={'rgba(255,255,255,0.1)'} placeholder={'Select'} className='select'>
                                                <option style={{ color: '#000' }} value="International passport">International passport</option>
                                                <option style={{ color: '#000' }} value="Driver's license">Driver's license</option>
                                                <option style={{ color: '#000' }} value="National ID card">National ID card</option>
                                            </Select>
                                        </InputGroup>
                                    </FormControl>

                                    <FormControl mb={10}>
                                        <Text mb={1}>Upload photo</Text>
                                        <Text mb={3} fontSize={'12px'}> Click on the box to select image</Text>
                                        <Box
                                            w={'200px'}
                                            h={'200px'}
                                            border={'dashed 1px #fff'}
                                            onClick={() => uploadVerifyIDRef.current.click()}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            bgPos={'0px'}
                                            bgImage={verifyIDImage?.name ? URL.createObjectURL(verifyIDImage) : user.verify ? user.verify.image : ''}
                                            cursor={'pointer'}></Box>
                                    </FormControl>

                                    <Button size={'md'} colorScheme='cyan' isDisabled={!verifyIDImage?.name || verifyIDType === '' || isFetching} onClick={() => verifyID()}>Upload</Button>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'}>
                                        Password update
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box mt={5} mb={5}>
                                    <FormControl mb={4}>
                                        <Text mb={3}>Current Password</Text>
                                        <InputGroup>
                                            <Input variant={''} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} type='password' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Old Password'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <Text mb={3}>New Password</Text>
                                        <InputGroup>
                                            <Input variant={''} onBlur={validatePassword} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type='password' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'New Password'} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={5}>
                                        <Text mb={3}>Confirm Password</Text>
                                        <InputGroup>
                                            <Input variant={''} onBlur={validatePassword} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'Confirm Password'} />
                                        </InputGroup>
                                    </FormControl>
                                    <Button onClick={() => handleChangePassword()} colorScheme={'teal'} isDisabled={handlePasswordValidate}>Submit</Button>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                    </Accordion>
                </Box>

                <Box>
                    <Box pos={'relative'} w={'200px'} cursor={'pointer'} onClick={() => uploadImageRef.current.click()}>
                        <Input ref={uploadImageRef} onChange={fileChangeHandler} type="file" accept='.png, .jpg, .jpeg' display={'none'} />
                        <Image
                            boxSize={{ base: '120px', md: '150px' }}
                            objectFit='cover'
                            src={user.image}
                            alt={user.firstname}
                            borderRadius={'full'}
                            mb={10}
                        />
                        <Icon pos={'absolute'} right={'0px'} top={'0px'} w={'25px'} h={'25px'} as={FiEdit} />
                    </Box>

                    <Text
                        fontWeight={'bold'} mb={2}>
                        {ucfirst(user.firstname)} {ucfirst(user.lastname)}
                    </Text>
                    <Text
                        fontWeight={'bold'} mb={2}>
                        {ucfirst(user.email)}
                    </Text>

                    <Button variant={'link'} onClick={() => logout()} mb={10}>
                        <Flex gap={3}>
                            <Icon as={AiOutlinePoweroff} w={'18px'} h={'18px'} mt={1} /> <Text>Logout</Text>
                        </Flex>
                    </Button>

                    <Card bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Text mb={5}>Withdrawal Addresses</Text>

                            <Flex gap={3}>
                                <ManageAddressButton addresses={addresses} setAddresses={setAddresses} />
                                <NewAddressButton paymentAddresses={paymentAddresses} setAddresses={setAddresses} />
                            </Flex>
                        </CardBody>
                    </Card>
                </Box>

            </SimpleGrid>
            <Box h={'100px'} />
        </>
    )
}

export default Profile