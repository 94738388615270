import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    FormControl,
    InputGroup,
    Input,
    Text,
    Select,
} from '@chakra-ui/react'
import { kBgColor, toastConfig } from '../utils/global'
import * as api from '../services/api_service'
import { toast } from 'react-toastify'
import { isStringEmpty } from '../utils/helper'

function NewAddressButton({paymentAddresses, setAddresses}) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isFetching, setIsFetching] = useState(false);
    const [network, setNetwork] = useState('')
    const [address, setAddress] = useState('')

    async function handleAddWallet() {
        setIsFetching(true)
        try {
            const resp = await api.addWallet({account: network, address: address})
            setAddresses((w) => [...w, resp.data.data])
            onClose()
            setIsFetching(false)
            toast.success("Wallet Added Successfully", toastConfig)
        } catch (error) {
            setIsFetching(false)
            toast.error(error.response.data.message, toastConfig)
        }
    }

    return (
        <>
            <Button onClick={onOpen} variant={'link'} size={'xs'} textDecor={'underline'} color={'blue.600'} fontSize={'small'}>New Address</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={kBgColor} color={'whiteAlpha.800'}>
                    <ModalHeader>Add Withdrawal Address</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Box mt={6} mb={3}>
                            <FormControl>
                                <Text mb={3}>Network</Text>
                                <InputGroup>
                                    <Select value={network} onChange={(e) => setNetwork(e.target.value)} variant={''} type='number' bgColor={'rgba(255,255,255,0.1)'} placeholder={'Select'}>
                                        {
                                            paymentAddresses.map((e, k) => (
                                                <option key={k} value={e.name} style={{color: '#000'}}>{e.name}</option>
                                            ))
                                        }
                                    </Select>
                                </InputGroup>
                            </FormControl>
                        </Box>

                        <Box mt={6} mb={3}>
                            <FormControl>
                                <Text mb={3}>Enter Address</Text>
                                <InputGroup>
                                    <Input variant={''} value={address} onChange={(e) => setAddress(e.target.value)} type='text' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'0x0'} />
                                </InputGroup>
                            </FormControl>
                        </Box>

                        <Box mt={6} mb={3}>
                            <Button colorScheme='teal' onClick={() => handleAddWallet()} isDisabled={isFetching || isStringEmpty(address) || isStringEmpty(network)}>Submit</Button>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default NewAddressButton