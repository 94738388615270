import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text, Card, CardBody, Icon, Button, Center } from '@chakra-ui/react'
import { kBgColor, toastConfig } from '../utils/global';
import { numberFormat, ucfirst } from '../utils/helper';
import { MdOutlineSell } from 'react-icons/md';
import * as api from '../services/api_service'
import { toast } from 'react-toastify';
// import UseAuth from '../hooks/UseAuth';

function OpenPositionCard({ order, setOrders, setTab }) {

    // const { user, setUser } = UseAuth();

    const [isProfit, setIsProfit] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [profit, setProfit] = useState();
    const [showClose, setShowClose] = useState(false);
    const _timer = useRef();
    // console.log(order.symbol.name, isProfit)

    useEffect(() => {
        setIsProfit(Math.floor(Math.random() * 5) > 2);
        setProfit(isProfit ? Math.floor(Math.random() * 23) : -(Math.floor(Math.random() * 90)));

        _timer.current = setInterval(() => {
            let _isProfit = Math.floor(Math.random() * 5) > 2;
            setIsProfit(_isProfit)
            setProfit(_isProfit ? Math.floor(Math.random() * 23) : -(Math.floor(Math.random() * 90)));
        }, (Math.random() * (1000 - 700) + 700));

        const starttime = new Date(order.created_at);
        const endtime = new Date(order.end_time);
        const diffCurrent = (order.now - 3600) - (starttime.getTime() / 1000);
        const diffTotal = (endtime.getTime() - starttime.getTime()) / 1000;
        console.log('backend now:', order.now - 3600)
        console.log('js now:', (new Date().getTime() / 1000) - 3600)
        console.log('created at:', new Date(order.created_at).getTime());
        console.log('End time', new Date(order.end_time).getTime());
        console.log('difference current', diffCurrent)
        console.log('difference total', diffTotal)

        return () => {
            clearInterval(_timer.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     async function _checkPosition() {
    //         const _roe = order.profit ? resolvePNL().roe : resolveLossPNL().roe;
    //         if (order.profit !== null && _roe >= order.profit) {
    //             setIsClosing(true)
    //             clearInterval(_timer.current);

    //             try {
    //                 const resp = await api.closePosition({ trade_id: order.id, roe: order.profit });
    //                 if (user?.demo) {
    //                     setUser((user_) => {
    //                       return { ...user_, demo_balance: user_.demo_balance + parseFloat(order.amount) }
    //                     })
    //                   } else {
    //                     setUser((user_) => {
    //                       return { ...user_, balance: user_.balance + parseFloat(order.amount) }
    //                     })
    //                   }
    //                 setOrders(resp.data.data)
    //             } catch (error) {
    //                 setIsClosing(false)
    //                 console.log(error.response.data)
    //                 toast.error(error.response.data.message, toastConfig)
    //             }
    //         }

    //         if (_roe <= -98) {
    //             setIsClosing(true)
    //             clearInterval(_timer.current);

    //             try {
    //                 const resp = await api.closePosition({ trade_id: order.id, roe: -100 });
    //                 setOrders(resp.data.data)
    //             } catch (error) {
    //                 setIsClosing(false)
    //                 console.log(error.response.data)
    //                 toast.error(error.response.data.message, toastConfig)
    //             }
    //         }
    //     }

    //     _checkPosition();
    // }, [profit])

    function resolveLossPNL() {

        if (order) {

            const starttime = new Date(order.created_at);
            const endtime = new Date(order.end_time);
            const diffCurrent = (order.now - 3600) - (starttime.getTime() / 1000);
            const diffTotal = (endtime.getTime() - starttime.getTime()) / 1000;

            // const maxLoss = (profit * order.amount) / 100;
            const _pnl = (diffCurrent / diffTotal) * ((order.amount * profit) / 100);

            if (diffCurrent < diffTotal) {
                return {
                    pnl: (_pnl).toFixed(2),
                    roe: ((_pnl / order.amount) * 100).toFixed(2)
                }
            } else {
                return {
                    pnl: order.amount * -1,
                    roe: -100
                }
            }
        } else {
            return {
                pnl: 0,
                roe: 0
            }
        }

    }

    function resolvePNL() {
        if (order) {
            const starttime = new Date(order.created_at);
            const endtime = new Date(order.end_time);
            const diffCurrent = (order.now - 3600) - (starttime.getTime() / 1000);
            const diffTotal = (endtime.getTime() - starttime.getTime()) / 1000;

            const maxProfit = (order.profit * order.amount) / 100;
            const _pnl = (diffCurrent / diffTotal) * maxProfit * (isProfit ? 1 : Math.random());

            if (diffCurrent < diffTotal) {
                return {
                    pnl: parseFloat((_pnl).toFixed(3)),
                    roe: parseFloat(((_pnl / order.amount) * 100).toFixed(2))
                }
            } else {
                return {
                    pnl: maxProfit,
                    roe: order.profit
                }
            }
        } else {
            return {
                pnl: 10,
                roe: 10
            }
        }

    }

    async function handleClosePosition() {
        setIsClosing(true)

        const _roe = order.profit ? resolvePNL().roe : resolveLossPNL().roe
        console.log(_roe)
        clearInterval(_timer.current);

        try {
            const resp = await api.closePosition({ trade_id: order.id, roe: _roe });
            setOrders(resp.data.data)
            setTab('closed')
            toast.success("Position Closed Successfully", toastConfig)
        } catch (error) {
            setIsClosing(false)
            console.log(error.response.data)
            toast.error(error.response.data.message, toastConfig)
        }
    }

    return (
        <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'dark-lg'}>
            <CardBody>
                <Flex justifyContent={'space-between'}>
                    <Flex gap={3}>
                        <Icon as={MdOutlineSell} h={'25px'} />
                        <Text fontWeight={'bold'}>{order.symbol.name}</Text>
                        {/* <Text fontSize={'small'} color={'whiteAlpha.600'}>Cross 1X</Text> */}
                    </Flex>
                    <Text fontSize={10} color={'whiteAlpha.700'} textDecor={'underline'}>{ucfirst(order.type)} trade</Text>
                </Flex>

                <Box pos={'relative'}>
                    <Flex justifyContent={'space-between'} pt={5} mb={3}>
                        <Box>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>PNL (USD)</Text>
                            <Text color={(resolvePNL().roe > 0 || resolveLossPNL().roe > 0) ? 'green.500' : (resolvePNL().roe < 0 || resolveLossPNL().roe < 0) ? 'red.500' : 'yellow.600'} fontWeight={'bold'}>{order.profit ? resolvePNL().pnl : resolveLossPNL().pnl}</Text>
                        </Box>

                        <Box textAlign={'right'}>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>ROE</Text>
                            <Text color={(resolvePNL().roe > 0 || resolveLossPNL().roe > 0) ? 'green.500' : (resolvePNL().roe < 0 || resolveLossPNL().roe < 0) ? 'red.500' : 'yellow.600'} fontWeight={'bold'}>{order.profit ? resolvePNL().roe : resolveLossPNL().roe}%</Text>
                        </Box>
                    </Flex>

                    <Flex justifyContent={'space-between'} mb={3}>
                        <Box>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>Entry Price ({order.symbol.sell})</Text>
                            <Text fontSize={'14px'}>{numberFormat(order.entry)}</Text>
                        </Box>

                        <Box>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>Size ({order.symbol.buy})</Text>
                            <Text fontSize={'14px'}>{numberFormat(order.buy_amount)}</Text>
                        </Box>

                        <Box textAlign={'right'}>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>Margin ({order.symbol.sell})</Text>
                            <Text fontSize={'14px'}>{numberFormat(order.sell_amount)}</Text>
                        </Box>
                    </Flex>

                    <Flex justifyContent={'space-between'} mb={5}>
                        <Box>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>TP ({order.symbol.sell})</Text>
                            <Text fontSize={'14px'}>{order.tp === null ? '--' : numberFormat(order.tp)}</Text>
                        </Box>

                        <Box>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>SL ({order.symbol.sell})</Text>
                            <Text fontSize={'14px'}>{order.tp === null ? '--' : numberFormat(order.sl)}</Text>
                        </Box>

                        <Box textAlign={'right'}>
                            <Text fontSize={'small'} color={'whiteAlpha.600'}>Type</Text>
                            <Text fontSize={'14px'}>{order.symbol.order_type === 'limit order' ? 'Limit' : 'Market'}</Text>
                        </Box>
                    </Flex>

                    <Button size={'xs'} colorScheme='telegram' mb={3} onClick={() => setShowClose(true)}>Close position</Button>

                    {/* <ScaleFade in={showClose} unmountOnExit={true}> */}
                    <Box pos={'absolute'} top={'0px'} display={showClose ? 'block' : 'none'} h={'100%'} bgColor={'rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(10px)'} w={'100%'}>
                        <Center w={'100%'} h={'100%'}>
                            <Box w={'200px'}>
                                <Box>
                                    <Text fontSize={'small'} color={'whiteAlpha.600'}>Close {order.symbol.name} position at:</Text>
                                </Box>
                                <Flex justifyContent={'space-between'} pt={5} mb={8}>
                                    <Box>
                                        <Text fontSize={'small'} color={'whiteAlpha.600'}>PNL (USD)</Text>
                                        <Text color={(resolvePNL().roe > 0 || resolveLossPNL().roe > 0) ? 'green.500' : (resolvePNL().roe < 0 || resolveLossPNL().roe < 0) ? 'red.500' : 'yellow.600'} fontWeight={'bold'}>{order.profit ? resolvePNL().pnl : resolveLossPNL().pnl}</Text>
                                    </Box>

                                    <Box textAlign={'right'}>
                                        <Text fontSize={'small'} color={'whiteAlpha.600'}>ROE</Text>
                                        <Text color={(resolvePNL().roe > 0 || resolveLossPNL().roe > 0) ? 'green.500' : (resolvePNL().roe < 0 || resolveLossPNL().roe < 0) ? 'red.500' : 'yellow.600'} fontWeight={'bold'}>{order.profit ? resolvePNL().roe : resolveLossPNL().roe}%</Text>
                                    </Box>
                                </Flex>
                                <Flex gap={4}>
                                    <Button size={'xs'} isDisabled={isClosing} bgColor='red.800' onClick={() => setShowClose(false)}>Cancel</Button>
                                    <Button size={'xs'} isDisabled={isClosing} bgColor='cyan.700' onClick={handleClosePosition}>Confirm</Button>
                                </Flex>
                            </Box>
                        </Center>
                    </Box>
                    {/* </ScaleFade> */}
                </Box>

            </CardBody>
        </Card>
    )
}

export default OpenPositionCard