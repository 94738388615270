import { useEffect, useState } from 'react'
import { Box, Container, Heading, Flex } from '@chakra-ui/react'
import Profile from '../../components/Profile';
import Settings from '../../components/Settings';
import { useLocation } from 'react-router-dom';
import ConnectedBots from '../../components/ConnectedBots';

function Account() {

  const tTypeSearch = useLocation().search;
  const tTypeSearchName = new URLSearchParams(tTypeSearch).get('to') || 'profile';
  const tTypeName = tTypeSearchName.toLowerCase();
  const [tab, setTab] = useState(tTypeName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  return (
    <>
      <Container maxW={'container.lg'}>
        <Box h={'auto'} mt={{ base: 5, md: 10 }} pb={'100px'}>
          <Flex justifyContent={'space-between'}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Account</Heading>
            <Flex>
              <Box mr={5}>
                <Box borderBottom={tab === 'profile' ? 'solid 2px #fff' : 'none'} color={tab === 'profile' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('profile')}>Profile</Box>
              </Box>
              <Box mr={5}>
                <Box borderBottom={tab === 'apps' ? 'solid 2px #fff' : 'none'} color={tab === 'apps' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('apps')}>Apps</Box>
              </Box>
              <Box mr={2}>
                <Box borderBottom={tab === 'settings' ? 'solid 2px #fff' : 'none'} color={tab === 'settings' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('settings')}>Settings</Box>
              </Box>
            </Flex>
          </Flex>

          {
            tab === 'profile' ? 
            <Profile /> :
            tab === 'apps' ?
            <ConnectedBots /> : 
            <Settings />
          }
        </Box>
      </Container>
    </>
  )
}

export default Account