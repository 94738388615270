import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Card, CardBody, Container, Flex, Heading, SimpleGrid, Skeleton, Spinner, Text } from '@chakra-ui/react'
import * as api from '../../services/api_service'
import { kBgColor } from '../../utils/global';
import { currencyFormat, ucfirst } from '../../utils/helper';
import UseAuth from '../../hooks/UseAuth';
import { toast } from 'react-toastify';

function Plan() {

    const [plans, setPlans] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isRequesting, setIsRequesting] = useState(false);
    const { user, setUser } = UseAuth();

    async function getPlans() {
        try {
            const resp = await api.plans();
            setPlans(resp.data);
            setIsFetching(false)
        } catch (error) {
            console.log(error.response.data)
            setIsFetching(false)
        }
    }

    async function requestPlan(plan_id) {
        setIsRequesting(true);
        try {
            const resp = await api.requestPlan({plan_id: plan_id})
            setUser((u) => ({...user, plan: plan_id, plan_request: 1}))
            toast.success(resp.data.message);
            setIsRequesting(false);
        } catch (error) {
            toast.error('Error occured. please try again after some time', 400);
            setIsRequesting(false);
        }
    }

    useEffect(() => {
        getPlans();
    }, [])

    const resolvePlan = useMemo(() => {
        return plans.length > 0 && user.plan !== null ? plans.filter((e) => e.id === user.plan)[0] : null
    }, [user, plans])

    return (

        <>
            <Container maxW={'container.lg'} pb={5}>
                <Box h={'auto'} mt={{ base: 5, md: 10 }} mb={10}>
                    <Flex justifyContent={'space-between'} mb={'50px'}>
                        <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Plans</Heading>
                    </Flex>
                    {user.plan_request === 1 && plans.length > 0 && <Text mb={5} color={'orange.500'} fontSize={'sm'}>You requested a plan. Kindly wait for approval ({ucfirst(`${resolvePlan?.name}`)})</Text>}
                    {user.plan_request !== 1 && plans.length > 0 && <Text mb={5} color={'green.500'} fontSize={'sm'}>Active plan: {resolvePlan ? ucfirst(`${resolvePlan?.name}`) : 'None'}</Text>}

                    {
                        !isFetching ?
                            <>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                                    {
                                        plans.map((plan, k) => (
                                            <Card bgColor={kBgColor} color={'whiteAlpha.800'} shadow={'dark-lg'} key={k}>
                                                <CardBody>
                                                    <Heading size={'md'} mb={10}>{ucfirst(plan.name)}</Heading>
                                                    <Text color={'whiteAlpha.500'} fontSize={'14px'}>Min. deposit</Text>
                                                    <Text fontSize={'3xl'} mb={5}>{currencyFormat(plan.min)}</Text>
                                                    <Text color={'whiteAlpha.500'} fontSize={'14px'}>Max. deposit</Text>
                                                    <Text fontSize={'xl'} mb={5}>{currencyFormat(plan.max)}</Text>
                                                    <Text color={'whiteAlpha.500'} fontSize={'14px'}>Max. trades per day</Text>
                                                    <Text fontSize={'sm'} mb={5}>{plan.trades} Executions</Text>
                                                    <Text color={'whiteAlpha.500'} fontSize={'14px'}>Support</Text>
                                                    <Text fontSize={'sm'} mb={7}>24hr support</Text>

                                                    {
                                                        user.plan_request === 0 && user.plan === plan.id ?
                                                            <Button variant={'ghost'} colorScheme='green' size={'sm'}>Active</Button> :
                                                            user.plan_request === 1 && user.plan === plan.id ?
                                                                <Button variant={'ghost'} colorScheme='yellow' size={'sm'} >
                                                                    <Flex gap={3}>
                                                                        <Spinner size={'sm'} />
                                                                        <Text>Plan Requested</Text>
                                                                    </Flex>
                                                                </Button> :
                                                                <Button variant={'outline'} colorScheme='cyan' size={'sm'} isDisabled={isRequesting} onClick={() => requestPlan(plan.id)}>Request Plan</Button>
                                                    }

                                                </CardBody>
                                            </Card>
                                        ))
                                    }
                                </SimpleGrid>
                            </> :
                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                                {
                                    Array.from(Array(3)).map((e, k) => (
                                        <Skeleton height={300} startColor='rgba(100,100,100,0.2)' endColor='rgba(0,0,0,0.2)' key={k} isLoaded={plans.length > 0} />
                                    ))
                                }
                            </SimpleGrid>
                    }
                </Box>
                <Box h={100} />
            </Container>
        </>
    )
}

export default Plan