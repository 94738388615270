
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from "react-router-dom";
import { Box, Grid, GridItem, Hide } from '@chakra-ui/react'
import TvMainChart from '../../components/tv/TvMainChart'
import TradeCard from '../../components/TradeCard'
import TraderSymbolHeader from '../../components/TraderSymbolHeader'
import OrderBook from '../../components/OrderBook'
import TraderOrders from '../../components/TraderOrders'
import Watchlist from '../../components/Watchlist'

import MobileMarketInfoDrawer from '../../components/MobileMarketInfoDrawer'
import MobileTradeDrawer from '../../components/MobileTradeDrawer'
import * as api from '../../services/api_service'
import UseAuth from '../../hooks/UseAuth';
import { isStringEmpty } from '../../utils/helper';
import { kCommissionRate, toastConfig } from '../../utils/global';
import { toast } from 'react-toastify';

function Trader() {

  const { user, isAuthenticated } = UseAuth();
  const symbolSearch = useLocation().search;
  const symbolSearchName = new URLSearchParams(symbolSearch).get('symbol') || 'btcusdt';
  const symbolName = symbolSearchName.toLowerCase();
  // console.log(symbolSearchName)

  const ws = useRef()
  // const [symbol, setSymbol] = useState('btcusdt')
  const [orderBook, setOrderBook] = useState(null)
  const [symbol24HrTicker, setSymbol24HrTicker] = useState(null)
  const [activeSymbolBuyPrice, setActiveSymbolBuyPrice] = useState('')
  // const isRendered = useRef(false)

  const [symbols, setSymbols] = useState([])
  const [watchlist, setWatchlist] = useState([])

  async function getDatas() {
    try {
      const resp = await Promise.all([
        api.symbols(),
        api.watchlist()
      ]);
      setSymbols(resp[0].data)
      setWatchlist(resp[1].data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDatas();
  }, [])

  const activeSymbol = useMemo(() => (
    symbols.length > 0 ? symbols.filter((e) => `${e.name}`.toLowerCase() === symbolName)[0] ?? null : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [symbols])

  async function getActiveSymbolBuyPrice(data) {
    try {
      let _data = `${data}`.toUpperCase() + 'USDT';
      const resp = await api.getSymbolPrice(_data, user.country)
      setActiveSymbolBuyPrice((parseFloat(resp.data.price)))
    } catch (error) {
      console.log('priceError: ', error.response.data)
    }
  }

  useEffect(() => {
    if (activeSymbol !== null) {
      getActiveSymbolBuyPrice(activeSymbol.buy)
    }
    // console.log(activeSymbol)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSymbol])

  const maxBuyPrice = useMemo(() => {
    if (!isStringEmpty(activeSymbolBuyPrice)) {
      return ((user.demo ? user.demo_balance : user.balance) / activeSymbolBuyPrice) * (1 - kCommissionRate)
    }
    return null
  }, [activeSymbolBuyPrice, user]);

  useEffect(() => {
    // if (isRendered.current) {

    if (isAuthenticated) {
      console.log('country', user.country)
      ws.current = new WebSocket(`wss://stream.binance.${user.country === 'United States' ? 'us' : 'com'}:9443/stream?streams=${symbolName}@ticker/${symbolName}@depth`)
      ws.current.addEventListener("open", (e) => {
        console.log("connection opened")
      });

      ws.current.addEventListener("message", (event) => {
        let _msg = JSON.parse(event.data)
        if (_msg.data.e === "depthUpdate") {
          let _ob = {
            ask: _msg.data.a,
            bid: _msg.data.b,
          }
          // console.log(_ob)
          setOrderBook(_ob)
        }

        if (_msg.data.e === "24hrTicker") {
          let _st = {
            change: _msg.data["p"],
            change_percent: _msg.data["P"],
            high: _msg.data["h"],
            low: _msg.data["l"],
            open: _msg.data["o"],
            close: _msg.data["c"],
            base_volume: _msg.data["v"],
            quote_volume: _msg.data["q"],
            symbol: _msg.data["s"],
          }
          // console.log(_ob)
          setSymbol24HrTicker(_st)
        }
        // console.log(_msg.data)
      });

      ws.current.addEventListener("error", (event) => {
        console.log("WebSocket error: ", event);
      });

      ws.current.addEventListener("ping", (e) => {
        ws.current.pong();
      });
    }

    // } else {
    //   isRendered.current = true
    // }

    return () => {
      ws.current && ws.current.close()
    }
  }, [symbolName, user, isAuthenticated])

  async function toggleWatchlist(symbol_id) {
    try {
      const resp = await api.toggleWatchlist({ symbol_id: symbol_id })
      setWatchlist(resp.data.data)
      toast.success(resp.data.message, { ...toastConfig, autoClose: 2000 })
    } catch (error) {
      console.log(error.response.data)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  return (
    <Box style={{ backgroundColor: 'black' }}>

      <TraderSymbolHeader symbol24HrTicker={symbol24HrTicker} symbols={symbols} toggleWatchlist={toggleWatchlist} watchlist={watchlist} />

      <Grid templateRows={'repeat(1, 1fr)'} templateColumns={'repeat(4, 1fr)'} style={{ height: 'inherit' }} bgColor={'rgb(0, 0, 0)'}>

        <GridItem colSpan={[4, 4, 3, 3]} style={{ height: 'inherit' }}>

          <Grid templateRows={'repeat(1, 1fr)'} templateColumns={'repeat(6, 1fr)'} style={{ height: 'inherit' }}>

            <GridItem display={{ base: 'none', md: 'block' }} colSpan={[0, 0, 2, 2]} bg={'blue'} style={{ height: 'inherit' }} bgColor={'rgb(0, 0, 0)'} >

              <Box ml={3}>

                <OrderBook orderBook={orderBook} />

              </Box>

            </GridItem>

            <GridItem colSpan={[6, 6, 4, 4]} style={{ height: 'inherit' }}>

              <Box h={{ base: '70vh', md: '60vh' }} mt={{ base: '0px', md: '1px' }} pl={2} pr={2} shadow={'xl'}>

                <TvMainChart symbol={symbolName.toUpperCase()} interval='30m' />

              </Box>

              <TraderOrders />

            </GridItem>

          </Grid>

        </GridItem>

        <GridItem display={{ base: 'none', md: 'block' }} colSpan={[0, 0, 1, 1]} bg={'blue'} style={{ height: 'inherit' }} bgColor={'rgb(0, 0, 0)'} >

          <Box mb={2}>

            <TradeCard symbol={activeSymbol} symbol24HrTicker={symbol24HrTicker} activeSymbolBuyPrice={activeSymbolBuyPrice} maxBuyPrice={maxBuyPrice} symbols={symbols} toggleWatchlist={toggleWatchlist} watchlist={watchlist} />

          </Box>

          <Box>

            <Watchlist watchlist={watchlist} toggleWatchlist={toggleWatchlist} symbols={symbols} symbol24HrTicker={symbol24HrTicker} />

          </Box>

        </GridItem>

      </Grid>

      <Hide above='md'>
        <Box
          w={'330px'} h={'auto'}
          bgColor={'transparent'}
          bottom={'145px'} pos={'fixed'} zIndex={3} left={'calc(50% - 330px/2)'}
          display={'flex'} >

          <MobileMarketInfoDrawer symbols={symbols} orderBook={orderBook} toggleWatchlist={toggleWatchlist} watchlist={watchlist} symbol24HrTicker={symbol24HrTicker} />

        </Box>

        <Box
          w={'330px'} h={'auto'}
          bgColor={'transparent'}
          bottom={'80px'} pos={'fixed'} zIndex={3} left={'calc(50% - 330px/2)'}
          display={'flex'} >

          <MobileTradeDrawer symbol={activeSymbol} symbol24HrTicker={symbol24HrTicker} activeSymbolBuyPrice={activeSymbolBuyPrice} maxBuyPrice={maxBuyPrice} symbols={symbols} toggleWatchlist={toggleWatchlist} watchlist={watchlist} />

        </Box>
      </Hide>

    </Box>

  )

}

export default Trader