import { Box, Card, CardBody, Center, Container, Flex, Heading, Icon, Image, SimpleGrid, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { BsCashCoin } from 'react-icons/bs'
import { currencyFormat, ucfirst } from '../../utils/helper'
import bubbleBg from '../../assets/images/bubble.png'
import referImg from '../../assets/images/refer.svg'
import { kBgColor } from '../../utils/global'
import UseAuth from '../../hooks/UseAuth'
import { MdContentCopy } from 'react-icons/md'
import { SiFigshare } from 'react-icons/si'
import { toast } from 'react-toastify'
import * as api from '../../services/api_service'
import ReferralWithdrawButton from '../../components/ReferralWithdrawButton'

function Referral() {

    const { user } = UseAuth()
    const copyText = useRef()

    const [isFetching, setIsFetching] = useState(false);
    const [downlines, setDownlines] = useState([]);
    const [bonuses, setBonuses] = useState([]);

    function copyToClipboard(e) {
        console.log(copyText.current.innerText);
        navigator.clipboard.writeText(copyText.current.innerText)
        toast.success("Copied to clipboard", { autoClose: 1000 })
    };

    useEffect(() => {
        async function getReferralData() {
            setIsFetching(true)
            try {
                const resp = await Promise.all([
                    api.downlines(),
                    api.downlineEarnings()
                ])
                setDownlines(resp[0].data.data)
                setBonuses(resp[1].data.data)
                setIsFetching(false)
            } catch (error) {
                setIsFetching(false)
                toast.error(error.response.data.message)
            }
        }

        getReferralData();
    }, [])

    return (
        <>
            <Container maxW={'container.lg'} pb={5}>
                <Box h={'auto'} mt={{ base: 5, md: 10 }}>
                    <Flex justifyContent={'space-between'} mb={'20px'}>
                        <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Referral</Heading>
                    </Flex>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                        <Box>
                            <Card bgColor={'rgba(40,94,97, 0.4)'} color={'whiteAlpha.800'} bgImage={bubbleBg} bgSize={'cover'}>
                                <CardBody>
                                    <Box p={5}>
                                        <Flex justifyContent={'space-between'}>
                                            <Box>
                                                <Text fontSize={'smaller'}>Referral Balance</Text>
                                                <Text fontSize={'3xl'}>{currencyFormat(user.referral_balance)}</Text>
                                                <Flex gap={3}>
                                                    {/* <Button variant={'link'}><Text fontSize={'smaller'} textDecor={'underline'}>Top up</Text></Button> */}
                                                    <ReferralWithdrawButton />
                                                </Flex>
                                            </Box>
                                            <Center>
                                                <Icon as={BsCashCoin} w={'45px'} h={'45px'} />
                                            </Center>
                                        </Flex>
                                    </Box>
                                </CardBody>
                            </Card>

                            <Card bgColor={kBgColor} p={5} color={'whiteAlpha.700'} mb={10}>
                                <CardBody>
                                    <Text fontSize={'2xl'} mb={3} mt={{ base: '-30px', md: 10 }}>Refer a friend to get referral bonuses on their deposits</Text>
                                    <Text mb={3}>
                                        share your link below to get amazing bonuses
                                    </Text>
                                    <Box shadow={'dark-lg'} p={3}>
                                        <Flex gap={2}>
                                            <Text ref={copyText} onClick={(e) => copyToClipboard(e)} _hover={{ color: 'blue.300' }} color={'blue.500'} fontSize={14} fontWeight={'semibold'} textDecor={'underline'}>{`${process.env.REACT_APP_HOME_URL}/join/${user.id}/referral`}</Text>
                                            <Icon ml={3} as={MdContentCopy} cursor={'pointer'} onClick={(e) => copyToClipboard(e)} _hover={{ color: 'blue.500' }} />
                                        </Flex>
                                    </Box>
                                </CardBody>
                            </Card>

                            <Box mb={'70px'}>
                                <Heading size={'md'} mb={8}>Downlines</Heading>
                                <TableContainer>
                                    <Table variant='unstyled' size={'sm'}>
                                        <Thead>
                                            <Tr>
                                                <Th textAlign={'left'}>Name</Th>
                                                <Th>Date Registered</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                downlines.length > 0 ?
                                                    downlines.map((e, i) => (
                                                        <Tr key={i} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                            <Td textAlign={'left'} color={'green.600'} fontSize={'13px'}>{ucfirst(e.downline.firstname)} {ucfirst(e.downline.lastname)}</Td>
                                                            <Td fontSize={'13px'}>
                                                                <Box height={'inherit'} >{e.downline.created_at}</Box>
                                                            </Td>
                                                        </Tr>
                                                    )) :
                                                    !isFetching ?
                                                        <Center mt={8}>
                                                            <Flex gap={5} pl={5}>

                                                                <Icon as={SiFigshare} />
                                                                <Text fontSize={'small'}>You have no downlines at the moment. <br />Share your referral link.</Text>

                                                            </Flex>
                                                        </Center> :
                                                        <Center p={5}><Spinner /></Center>
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            <Box mb={'70px'}>
                                <Heading size={'md'} mb={8}>Referral Bonuses</Heading>
                                <TableContainer maxH={'300px'} overflowY={'auto'}>
                                    <Table variant='unstyled' size={'sm'}>
                                        <Thead>
                                            <Tr>
                                                <Th textAlign={'left'}>Date Registered</Th>
                                                <Th textAlign={'center'}>Downline</Th>
                                                <Th textAlign={'right'}>Amount</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                bonuses.length > 0 ?
                                                    bonuses.map((e, i) => (
                                                        <Tr key={i} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                            <Td textAlign={'left'} color={'green.600'} fontSize={'13px'}>{e.created_at}</Td>
                                                            <Td>{ucfirst(e.downline.firstname)} {ucfirst(e.downline.lastname)}</Td>
                                                            <Td bgColor={'rgba(27, 85, 59, 0.282)'} fontSize={'13px'}>
                                                                <Box height={'inherit'} textAlign={'right'} >{currencyFormat(`${e.amount}`)}</Box>
                                                            </Td>
                                                        </Tr>
                                                    )) :
                                                    !isFetching ?
                                                        <Center mt={8}>
                                                            <Flex gap={5} pl={5}>

                                                                <Icon as={SiFigshare} />
                                                                <Text fontSize={'small'}>You have no downlines at the moment. <br />Share your referral link.</Text>

                                                            </Flex>
                                                        </Center> :
                                                        <Center p={5}><Spinner /></Center>
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>

                        <Box mb={10}>
                            <Center>
                                <Image src={referImg} width={{ base: '100%', md: '70%' }} />
                            </Center>
                        </Box>
                    </SimpleGrid>
                </Box>
            </Container>
        </>
    )
}

export default Referral