import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { Box } from '@chakra-ui/react'
import Loader from '../components/Loader';
import AuthNav from '../components/auth/AuthNav'

function AuthLayout() {

    const isLoading = false;

    return (
        <>
            <ToastContainer theme='dark' />
            <Box bgColor={'rgb(0, 0, 0)'} color={'whiteAlpha.800'} w={'100%'} minH={'100vh'}>
                {
                    isLoading ? <Loader /> :
                        <Box>
                            <AuthNav />
                            <Outlet />
                        </Box>
                }
            </Box>
        </>
    )
}

export default AuthLayout