import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
} from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { kBgColor, toastConfig } from '../utils/global'
import * as api from '../services/api_service'
import { toast } from 'react-toastify'
import { FaRegTrashAlt } from 'react-icons/fa'

function ManageAddressButton({ addresses, setAddresses }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isFetching, setIsFetching] = useState(false);

    async function handleDeleteWallet(id) {
        setIsFetching(true)
        try {
            await api.deleteAddress(id)
            setAddresses((w) => w.filter((e) => e.id !== parseInt(id)))
            setIsFetching(false)
            toast.success("Wallet Deleted Successfully", toastConfig)
        } catch (error) {
            setIsFetching(false)
            toast.error(error.response.data.message, toastConfig)
        }
    }

    return (
        <>
            <Button onClick={onOpen} variant={'link'} size={'xs'} textDecor={'underline'} color={'blue.600'} fontSize={'small'}>Manage Addresses</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={kBgColor} color={'whiteAlpha.800'}>
                    <ModalHeader>Manage Withdrawal Address</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <TableContainer>
                            <Table variant='striped' colorScheme='blackAlpha' size={'sm'}>
                                <TableCaption>Saved withdrawal addresses</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Action</Th>
                                        <Th>Network</Th>
                                        <Th>Address</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        addresses.map((e, k) => (
                                            <Tr key={k}>
                                                <Td>
                                                    <Button variant={'link'} size={'sm'} onClick={() => { handleDeleteWallet(e.id) }} color={'red.700'} isDisabled={isFetching}>
                                                        <FaRegTrashAlt/>
                                                    </Button>
                                                    </Td>
                                                <Td>{e.account}</Td>
                                                <Td>{e.address}</Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ManageAddressButton