import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import { kBgColor } from '../../utils/global'
import logoImg from '../../assets/images/logo.png'

function AuthNav() {
    return (
        <Box bgColor={kBgColor} pos={'fixed'} p={5} w={'100%'} shadow={'xl'} zIndex={11} borderBottom={'solid 3px rgba(200,200,200,0.4)'}>
            <Box textDecoration={'underline'} textDecorationThickness={'4px'} textDecorationColor={'cyan.700'} fontSize={'17px'}>
                <Image src={logoImg} w={'35px'} />
            </Box>
        </Box>
    )
}

export default AuthNav