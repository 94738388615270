import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Input,
    Text,
    Select,
    Flex,
    Center,
    Icon,
    Spinner,
} from '@chakra-ui/react'
import { kBgColor, toastConfig } from '../utils/global'
import * as api from '../services/api_service'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import UseAuth from '../hooks/UseAuth'
import ManageAddressButton from './ManageAddressButton'
import NewAddressButton from './NewAddressButton'
import { currencyFormat } from '../utils/helper'
import { BsShieldFillCheck } from 'react-icons/bs';

function ReferralWithdrawButton() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user, setUser } = UseAuth();
    const navigate = useNavigate();
    
    const [isFetching, setIsFetching] = useState(false); 
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [paymentAddresses, setPaymentAddresses] = useState([]);
    const [wAmount, setWAmount] = useState('');
    const [wWallet, setWWallet] = useState('');

    async function getWalletData() {
      setIsFetching(true)
      try {
        const resp = await Promise.all([
          api.addresses(),
          api.paymentAddresses(),
        ])
        console.log(resp)
        setAddresses(resp[0].data.data)
        setPaymentAddresses(resp[1].data.data)
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false)
        console.log(error.response.data) 
        toast.error("Error fetching wallet data", toastConfig)
      }
    }
  
    useEffect(() => {
      getWalletData();
    }, [])
    

    async function handleWithdraw() {
        setIsWithdrawing(true)
        try {
          const resp = await api.withdraw('referral', { wallet: wWallet, amount: wAmount })
          setIsWithdrawing(false)
          setUser((user_) => ({ ...user_, referral_balance: (user_.referral_balance - resp.data.data.amount) }))
          navigate('/user/wallet/transactions?type=withdrawals');
          toast.success("Withdrawal Request Sent", toastConfig)
        } catch (error) {
          console.log(error)
          setIsWithdrawing(false)
          toast.error(error.response.data.message, { ...toastConfig, autoClose: 5000 })
        }
      }

    return (
        <>
            <Button onClick={onOpen} variant={'link'}><Text fontSize={'smaller'} textDecor={'underline'}>Withdraw</Text></Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={kBgColor} color={'whiteAlpha.800'}>
                    <ModalHeader>Referral Bonus</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                    {!isFetching ?
                        <Box textAlign={'center'} p={5}>
                            <Flex justifyContent={'space-between'}>
                                <Text mb={8}>Withdraw</Text>
                                <Link as={RouterLink} to={'/user/wallet/transactions?type=withdrawals'}>
                                    <Text fontSize={'small'} textDecor={'underline'} color={'blue.500'}>Withdrawal History</Text>
                                </Link>
                            </Flex>

                            <Text mb={2} color={'whiteAlpha.600'} fontSize={'small'}>Amount (USD)</Text>
                            <Input w={'120px'} placeholder='0.00' type='number' value={wAmount} onChange={(e) => setWAmount(e.target.value)} textAlign={'center'} fontSize={'30px'} variant={'unstyled'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} pb={3} />
                            <Box mb={8} mt={1} fontSize={13}>
                                {
                                    user.demo ?
                                        <Text color={'yellow.500'}>Switch to live account</Text> :
                                        <Text color={'green.500'}>Available bonus: {currencyFormat(user.referral_balance)}</Text>
                                }
                            </Box>

                            <Text color={'whiteAlpha.600'} fontSize={'small'} mb={2}>Select address</Text>
                            <Center>
                                <Flex gap={4} mb={3}>

                                    <NewAddressButton paymentAddresses={paymentAddresses} setAddresses={setAddresses} />
                                    <ManageAddressButton addresses={addresses} setAddresses={setAddresses} />
                                </Flex>
                            </Center>
                            <Center mb={10}>
                                <Select placeholder={'Select'} type='number' textAlign={'center'} value={wWallet} onChange={(e) => setWWallet(e.target.value)} fontSize={'20px'} variant={'unstyled'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} w={'200px'}>
                                    {
                                        addresses.length > 0 &&
                                        addresses.map((e, k) => (
                                            <option key={k} value={e.id} style={{ color: '#000', fontSize: '12px' }}>{e.account} {e.address}</option>
                                        ))
                                    }
                                </Select>
                            </Center>

                            <Text fontSize={'smaller'} mb={8}>
                                <Text color={'whiteAlpha.600'}>Secured by</Text>
                                <Text><Icon color={'blue.400'} as={BsShieldFillCheck} /> {process.env.REACT_APP_NAME} pay</Text>
                            </Text>

                            <Button colorScheme='cyan' w={'180px'} size={'sm'} onClick={() => handleWithdraw()} isDisabled={isWithdrawing}>Submit</Button>
                        </Box>: 
                        <Center>
                            <Spinner />
                        </Center>
                        }
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ReferralWithdrawButton