import { NavLink, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Box, Center, Flex, Show, Text, Icon, Image } from '@chakra-ui/react'
import { TbDeviceAnalytics, TbWallet } from 'react-icons/tb'
// import { MdOutlineSell } from 'react-icons/md'
import { AiOutlineHome } from 'react-icons/ai'
import { LuClipboardList } from "react-icons/lu";
// import { FaAngleDown } from 'react-icons/fa'
import { kBgColor, toastConfig } from '../utils/global'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
} from '@chakra-ui/react'
import style from '../assets/modules/navheader.module.css'
import UseAuth from '../hooks/UseAuth'
import { currencyFormat } from '../utils/helper'
// import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';
import * as api from '../services/api_service'
import logoImg from '../assets/images/logo.png'
import { BsThreeDots } from 'react-icons/bs'

function ProfileMenuImage() {
    const { user } = UseAuth()

    return (
        <Box>
            <Center w={'35px'} h={'35px'} bgColor={'cyan.800'} borderRadius={'100%'} fontSize={'18px'} fontWeight={'bold'}>{user.firstname[0].toUpperCase()}</Center>
        </Box>
    )
}

function NavHeader() {

    const { user, setUser, logout, liveTradingBalance, demoTradingBalance } = UseAuth()
    const [isSwitching, setIsSwitching] = useState(false)
    const navigate = useNavigate()

    const balanceToggle = async () => {
        setIsSwitching(true)
        try {
            const resp = await api.walletToggle();
            setUser((_user) => ({ ..._user, demo: !user?.demo }))
            toast.success(resp.data.message, toastConfig)
            setIsSwitching(false)
        } catch (error) {
            console.log(error)
            setIsSwitching(false)
        }
    }

    return (
        <>
            <Box bgColor={kBgColor} pos={'fixed'} p={5} w={'100%'} shadow={'xl'} zIndex={1}>
                <Flex justifyContent={'space-between'}>
                    <Box textDecoration={'underline'} textDecorationThickness={'4px'} textDecorationColor={'cyan.700'} fontSize={'17px'}>
                        <Flex w={'40px'} h={'40px'} justifyContent={'center'} alignItems={'center'}>
                            <Image src={logoImg} w={'23.9px'} h={'20px'} />
                        </Flex>
                    </Box>
                    <Show above={'md'}>
                        <Flex color={'whiteAlpha.600'} mt={2}>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/analytics'}><Box p={"0px 5px"} m={"0px 5px"}>Explore</Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/trader'}><Box p={"0px 5px"} m={"0px 5px"}><div className='activee'>Charts</div></Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/orders'}><Box p={"0px 5px"} m={"0px 5px"}>Orders</Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/wallet'}><Box p={"0px 5px"} m={"0px 5px"}>Wallet</Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/plans'}><Box p={"0px 5px"} m={"0px 5px"}>Plans</Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/watchlist'}><Box p={"0px 5px"} m={"0px 5px"}>Watchlist</Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/referral'}><Box p={"0px 5px"} m={"0px 5px"}>Referral</Box></NavLink>
                            <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/account?to=settings'}><Box p={"0px 5px"} m={"0px 5px"}>Account</Box></NavLink>
                        </Flex>
                    </Show>
                    <Flex>

                        <Menu>
                            <MenuButton>
                                <Flex>
                                    <Box textAlign={'right'} mr={4}>
                                        <Text fontSize={10}>{user.demo ? 'PRACTICE ACCOUNT' : 'LIVE ACCOUNT'}</Text>
                                        <Text>{currencyFormat(user.demo ? (user.demo_balance + demoTradingBalance) : (user.balance + liveTradingBalance))}</Text>
                                    </Box>
                                    <ProfileMenuImage />
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor={kBgColor}>
                                <MenuItem onClick={() => navigate('/user/account?to=profile')} bgColor={kBgColor} _hover={{ 'bgColor': 'rgba(20, 20, 160, 0.31)' }}>
                                    <Text fontSize={'sm'}>Profile</Text>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem bgColor={kBgColor} onClick={() => !isSwitching ? balanceToggle() : undefined} _hover={{ 'bgColor': 'rgba(20, 20, 160, 0.31)' }}><Text fontSize={'sm'}>Switch to {user.demo ? 'live' : 'demo'}</Text></MenuItem>
                                <MenuDivider />
                                <MenuItem bgColor={kBgColor} onClick={() => logout()} _hover={{ 'bgColor': 'rgba(20, 20, 160, 0.31)' }}><Text fontSize={'sm'}>Logout</Text></MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Flex>
            </Box>

            <Box
                w={'100%'} h={'auto'}
                bgColor={kBgColor}
                pos={'fixed'} bottom={'-4px'} left={'0px'} zIndex={'3'}
                display={{ base: 'block', md: 'none' }}
                shadow={'dark-lg'}>

                <Flex>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/watchlist'}>
                            <Icon fontSize={'28px'} as={LuClipboardList} /><br />
                            <Text fontSize={'11px'}>Watchlist</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/trader'}>
                            <Icon fontSize={'28px'} as={TbDeviceAnalytics} /><br />
                            <Text fontSize={'11px'} fontWeight={'bold'}>Chart</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/analytics'}>
                            <Icon fontSize={'28px'} as={AiOutlineHome} /><br />
                            <Text fontSize={'11px'}>Explore</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/wallet'}>
                            <Icon fontSize={'28px'} as={TbWallet} /><br />
                            <Text fontSize={'11px'}>Wallet</Text>
                        </NavLink>
                    </Box>
                    <Box color={'whiteAlpha.600'} flex={1} textAlign={'center'} p={"10px 5px"} m={"0px 5px"}>
                        <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/more'}>
                            <Icon fontSize={'28px'} as={BsThreeDots} /><br />
                            <Text fontSize={'11px'}>More</Text>
                        </NavLink>
                    </Box>
                </Flex>
            </Box>

        </>
    )
}

export default NavHeader